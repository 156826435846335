
import getters from './getters'
import callbacks from './callbacks'
import { objMap } from './values'
import axios from 'axios'
import de from '../../language/de.js'

const filterMap = {}
for (const name in callbacks) {
  filterMap[name] = {
    callback: callbacks[name],
    values: []
  }
}

const updateFilterMap = state => {
  for (const name in state) {
    if (filterMap[name]) {
      filterMap[name].values = state[name].values
    }
  }
}

const getItemValues = (name, item) => {
  // TODO -> validate object values ....?! -> will also be done in setValue
  if (typeof objMap[name] === 'function') return objMap[name](item)
  return []
}

export default {

  resetFilter (context) {
    this.dispatch('results/setItems', Object.values(context.rootGetters['products/items']))
      .then(() => context.commit('resetValues'))
  },

  setValue (context, { name, title, value }) {
    const isSet = getters.isSet(context.state)
    if (!isSet(name, value) || context.state[name].values.length === 1) {
      context.commit('resetValues')
      context.commit('clearCallstack')
    }
    context.commit('setValue', { name, value })
    context.commit('pushCallstack', { name, title, value }) // should only be done on action setValue

    updateFilterMap(context.state)

    this.dispatch('results/filterProducts', filterMap)
      .then(() => context.dispatch('setFilterByResults'))
  },

  setFilterByResults (context) {
    const items = context.rootGetters['results/items']
    const values = {}
    for (const name in context.state) {
      if (context.state[name].values.length !== 1) { // for all implicit values
        if (!values[name]) values[name] = { name: name, value: [] }
        for (let i = 0; i < items.length; i++) {
          const newValues = getItemValues(name, items[i])
          for (let j = 0; j < newValues.length; j++) {
            if (values[name].value.indexOf(newValues[j]) < 0) values[name].value.push(newValues[j])
          }
        }
      }
    }

    // TODO -> do a more elegant way -> respect vdom update interval
    const valueList = Object.values(values)
    for (let i = 0; i < valueList.length; i++) {
      if(!valueList[i].name.startsWith('pdf_')) {
        context.commit('setValue', { name: valueList[i].name, value: valueList[i].value })
      }
    }
  }, 

  
  setCompositionForPdf (context, { name, title, value }) {
    context.commit('setDataForPdf', { name, title, value })
  }, 


  downloadPdf (context, {item, shorthand, projectName, products, labels, size, shade}) {
      axios({
        method: 'post',
        url:  window.apiPrefix + '/api/pdf',
        data: {
          pdf_info: "pdf_" + shorthand,
          item: item,
          products: products,
          labels: labels,
          project_name: projectName,
          translation: de,
          size: size,
          shade: shade
        },
        responseType: 'arraybuffer'
      })
      .then(resp => {
        
        if (resp.status === 200) {

          var file = new Blob([resp.data], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = fileURL;
          let downloadFileName = "pdf_" + shorthand
          if(projectName !== "") {
            downloadFileName = projectName + " - " +  context.state["pdf_" + shorthand].title
          } 
          a.download = downloadFileName;
          a.click();
          window.URL.revokeObjectURL(fileURL);
        }
        else {
          console.log(resp)
        }
      })
      .catch(err => {
        console.log(err)
        // context.commit('setFormError', { message: getString('contact.alert.error') })
      })
  }



}
