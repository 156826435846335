//import { validate } from './values'

export default {
  setProductValue(state, { item, structure, product }) {
    let shorthand = item.productData.shorthand;
    if (shorthand.includes("Ö")) {
      shorthand = shorthand.replace("Ö", "O");
    }
    if (state.items[shorthand]) {
      state.items[shorthand].productData.systemStructure.find(
        (element) => element.type === structure.type
      ).selectedProduct = product; 
      state.items[shorthand].productData.systemStructure.find(
        (element) => element.type === structure.type
      ).changedProduct = true;
      let otherType = "";
      if (structure.type === "finalCoatLayer1" || structure.type === "finalCoatLayer2") {
        otherType = structure.type === "finalCoatLayer1" ? "finalCoatLayer2" : "finalCoatLayer1";
      }
      if (structure.type === "additionalCoatLayer1" || structure.type === "additionalCoatLayer2") {
        otherType = structure.type === "additionalCoatLayer1" ? "additionalCoatLayer2" : "additionalCoatLayer1";
      }
      if (otherType !== "" && item.productData.shorthand !== 'P4' && state.items[shorthand].productData.systemStructure.find(
          (element) => element.type === otherType
        )) {
        state.items[shorthand].productData.systemStructure.find(
          (element) => element.type === otherType
        ).selectedProduct = product; 
        state.items[shorthand].productData.systemStructure.find(
          (element) => element.type === otherType
        ).changedProduct = true;
      }
    }
  }
}
