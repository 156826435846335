<template>
  <div class="composition-wrapper col-12 py-4">
    <template v-for="(structure, index) in item.productData.systemStructure">
      <div
        :key="index"
        class="row composition-row border-bottom"
        v-if="structure.products.length == 1"
      >
        <div class="d-flex col-6 pr-0 py-1 structure-product">
          <span class="font-weight-bold" style="padding-right: 5px"
            >{{ structure.layer }}{{ " " }}</span
          >
          <span class="composition-material-structure">
            {{ getProductName(structure.selectedProduct, structure.products) }}
          </span>
        </div>
        <div
          class="d-flex col-3 py-1 pr-0 structure-consumption"
          style="justify-content: flex-end"
          v-html="getProductConsumption(structure.selectedProduct)"
        ></div>
        <div
          class="d-flex col-3 py-1 pr-0 text-left structure-unit"
          v-html="getProductUnit(structure.selectedProduct)"
        ></div>
      </div>

      <CustomSelectMobile
        v-else
        :structure="structure"
        :item="item"
        :key="index"
      ></CustomSelectMobile>
    </template>
  </div>
</template>

<script>
import CustomSelectMobile from "./CustomSelectMobile";
import { mapGetters } from "vuex";
import { getString } from "../../../language";
import { products } from "@/store/filter/values";

export default {
  name: "StoCscResultItemStructureComposition",
  props: {
    item: Object,
    image: String,
  },
  data: function () {
    return {
      allProducts: products,
    };
  },
  components: {
    CustomSelectMobile,
  },
  computed: {
    ...mapGetters({
      formData: "stats/formData",
      formState: "stats/formState",
    }),
  },
  methods: {
    getLocalizedString(key) {
      return getString(key);
    },
    getProductName(product, products) {
      let productName = "";
      if (this.allProducts[product]) {
        productName = this.allProducts[product].title;
        if (products.length > 1) {
          if (this.allProducts[product].fineness !== "") {
            productName += ` ${this.allProducts[product].fineness}`;
          } else {
            let otherProducts = products.filter((p) => p !== product);
            let otherProductNames = otherProducts.map(
              (p) => this.allProducts[p].title
            );
            if (otherProductNames.includes(productName)) {
              productName += ` (${this.allProducts[product].containerType})`;
            }
          }
        }
      }
      return productName;
    },
    getProductConsumption(product) {
      if (product === 0) {
        return "";
      }
      if (this.allProducts[product]) {
        return (
          this.$store.getters["stats/formData"].size *
          this.allProducts[product].consumption
        ).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      return "";
    },
    getProductUnit(product) {
      if (product === 0) {
        return "";
      }
      if (this.allProducts[product]) {
        return this.allProducts[product].unit;
      }
      return "";
    },
  },
};
</script>

<style scoped>
.composition-row .font-weight-bold {
  color: black !important;
}

.composition-row > div {
  display: flex;
  align-items: center;
}
</style>