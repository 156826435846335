export default {
  image: 'oe4.jpg',
  productData: {
    name: 'Ökologisches System Ö4',
    shorthand: "Ö4",
    advantages: [
      "Ökologisch",
      "Konservierungsmittelfrei",
      "Feuchteregulierend",
      "Kreative Optik"
    ],
    technical: [
      "Allergiker-geeignet",
      "Feuchtemanagement",
      "Feuchtraum WO-I",
      "Strukturierte Flächen",
      "Glanzgrad stumpfmatt",
      "Erhöhte optische Ansprüche",
      "Nature Plus",
      "TÜV",
      "frei von KM (Schlussbeschichtung)"
    ],
    systemStructure: [
      {
        type: 'subsurface',
        layer: 1,
        products: [0],
        selectedProduct: 0,
        changedProduct: false
      },
      {
        type: 'joints',
        layer: 2,
        products: [1],
        selectedProduct: 1,
        changedProduct: false
      },
      {
        type: 'intermediateCoat',
        layer: 3,
        products: [28],
        selectedProduct: 28,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer1',
        layer: 4,
        products: [38],
        selectedProduct: 38,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer2',
        layer: 4,
        products: [38],
        selectedProduct: 38,
        changedProduct: false
      }
    ],
    construction: {
      composition: [
        {
          title: { de: 'Systemgruppen' },
          output: 'Ökologisches System',
          type: 'systemGroup',
          value: 'systemgroup-2'
        },
        {
          title: { de: 'Räume' },
          output: 'Wohnräume',
          type: 'room',
          value: 'room-1'
        },
        {
          title: { de: 'Räume' },
          output: 'Bad',
          type: 'room',
          value: 'room-2'
        },
        {
          title: { de: 'Räume' },
          output: 'Flur',
          type: 'room',
          value: 'room-4'
        },
        {
          title: { de: 'Räume' },
          output: 'Keller',
          type: 'room',
          value: 'room-5'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Schadstoffgeprüft',
          type: 'environmentHealth',
          value: 'environmenthealth-1'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'TÜV',
          type: 'environmentHealth', 
          value: 'environmenthealth-2'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'natureplus',
          type: 'environmentHealth', 
          value: 'environmenthealth-3'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'SentinelHaus',
          type: 'environmentHealth', 
          value: 'environmenthealth-4'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Konservierungsmittelfrei (System)',
          type: 'environmentHealth', 
          value: 'environmenthealth-6'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Konservierungsmittelfrei (Farbe/Putz)',
          type: 'environmentHealth', 
          value: 'environmenthealth-7'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Allergiker-geeignet',
          type: 'environmentHealth', 
          value: 'environmenthealth-8'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Stark mechanisch',
          type: 'resilience', 
          value: 'resilience-3'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Langlebig',
          type: 'resilience', 
          value: 'resilience-7'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Matt',
          type: 'designSurface', 
          value: 'designsurface-1'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Strukturiert',
          type: 'designSurface', 
          value: 'designsurface-7'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Erhöhte optische Ansprüche',
          type: 'designSurface', 
          value: 'designsurface-8'
        },
        {
          title: { de: 'Verarbeitung' },
          output: 'Einfach',
          type: 'processing', 
          value: 'processing-1'
        },
        {
          title: { de: 'Verarbeitungszeit' },
          output: 'Stufe 2',
          type: 'processingTime', 
          value: 'processingtime-2'
        },
        {
          title: { de: 'Preisniveau' },
          output: '€',
          type: 'priceLevel', 
          value: 'pricelevel-1'
        }
      ]
    }
  }
}
