
export const trackCallstack = (gtm, callstack) => {
  let label = ''
  for (let i = callstack.length - 1; i >= 0; i--) {
    if (label.length > 0) label += ' : '
    label += callstack[i].title.split('&shy;').join('').split('<sup>').join('').split('</sup>').join('').split('<sub>').join('').split('</sub>').join('')
  }
  gtm.trackEvent({
    event: 'filter',
    category: 'userFlow',
    action: 'filter',
    label: label,
    value: null
  })
}

const consoleLog = (objectToLog) => {
    console.table(objectToLog)
}

export const dataLayerPush = (trackingObject) => {
    try {
        window.dataLayer.push(trackingObject);
    } catch (e) {
        consoleLog(e);
    }
}

export const getDataLayerDefault = (type = 'field') => {
    if (type === 'field') {
        return {
            event: 'editForm',
            formName: 'kontakt',
            field: '',
            fieldType: '',
            focus: '',
            filledStatus: '',
            validationStatus: '',
            fieldInput: ''
        }
    }
    return {}
}