export default {
  image: 'p4.jpg',
  productData: {
    name: 'Premiumsystem P4',
    shorthand: "P4",
    advantages: [
      "Optisches Highlight",
      "Kreative Optik",
      "Feuchteregulierend",
      "Langlebig"
    ],
    technical: [
      "Feuchtemanagement",
      "Glatte Flächen",
      "Glanzgrad seidenmatt/seidenglänzend",
      "Erhöhte optische Ansprüche",
      "TÜV",
      "frei von KM (Schlussbeschichtung)"
    ],
    systemStructure: [
      {
        type: 'subsurface',
        layer: 1,
        products: [0],
        selectedProduct: 0,
        changedProduct: false
      },
      {
        type: 'joints',
        layer: 2,
        products: [1],
        selectedProduct: 1,
        changedProduct: false
      },
      {
        type: 'surfaceFilling',
        layer: 3,
        products: [51, 52],
        selectedProduct: 51,
        changedProduct: false
      },
      {
        type: 'firstCoat',
        layer: 4,
        products: [10],
        selectedProduct: 10,
        changedProduct: false
      },
      {
        type: 'glue',
        layer: 5,
        products: [23],
        selectedProduct: 23,
        changedProduct: false
      },
      {
        type: 'wallCovering',
        layer: 6,
        products: [24, 25, 26],
        selectedProduct: 24,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer1',
        layer: 7,
        products: [49, 50],
        selectedProduct: 49,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer2',
        layer: 8,
        products: [10],
        selectedProduct: 10,
        changedProduct: false
      },
      {
        type: 'additionalCoatLayer1',
        layer: 9,
        products: [48],
        selectedProduct: 48,
        changedProduct: false
      },
      {
        type: 'additionalCoatLayer2',
        layer: 9,
        products: [48],
        selectedProduct: 48,
        changedProduct: false
      }
    ],
    construction: {
      composition: [
        {
          title: { de: 'Systemgruppen' },
          output: 'Premiumsystem',
          type: 'systemGroup',
          value: 'systemgroup-4'
        },
        {
          title: { de: 'Räume' },
          output: 'Wohnräume',
          type: 'room',
          value: 'room-1'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Schadstoffgeprüft',
          type: 'environmentHealth',
          value: 'environmenthealth-1'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'TÜV',
          type: 'environmentHealth', 
          value: 'environmenthealth-2'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Konservierungsmittelfrei (Farbe/Putz)',
          type: 'environmentHealth', 
          value: 'environmenthealth-7'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Langlebig',
          type: 'resilience', 
          value: 'resilience-7'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Satin',
          type: 'designSurface', 
          value: 'designsurface-3'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Glatt',
          type: 'designSurface', 
          value: 'designsurface-6'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Erhöhte optische Ansprüche',
          type: 'designSurface', 
          value: 'designsurface-8'
        },
        {
          title: { de: 'Verarbeitungszeit' },
          output: 'Stufe 3',
          type: 'processingTime', 
          value: 'processingtime-3'
        },
        {
          title: { de: 'Preisniveau' },
          output: '€€€',
          type: 'priceLevel', 
          value: 'pricelevel-3'
        }
      ]
    }
  }
}
