<template>
  <div class="alert alert-secondary d-flex font-weight-bold filter-header">
    <h5 class="p-0 m-0">{{ headline }}</h5>
    <div class="ml-auto">
      <small class="link" v-on:click="resetFilter()">{{ reset }}</small>
    </div>
  </div>
</template>

<script>
import { getString } from "../../language";

export default {
  name: "StoCscFilterHeader",
  data: function () {
    return {
      headline: getString("filter.headline"),
      reset: getString("filter.reset"),
    };
  },
  methods: {
    resetFilter: function (value) {
      this.$gtm.trackEvent({
        event: "clickElement",
        category: "eigenschaften",
        action: "button",
        label: "Eigenschaften zurücksetzen [X]",
        value: null,
      });

      this.$store.dispatch("filter/resetFilter");
      this.$store.dispatch("stats/setFormData", {
        name: "size",
        value: 0,
      });
      this.$store.dispatch("stats/setFormData", {
        name: "name",
        value: "",
      });
      this.$store.dispatch("stats/setFormData", {
        name: "shade",
        value: "white",
      });
      this.$store.dispatch("contact/resetForm");
      this.$store.dispatch("contact/hideForm");
    },
  },
};
</script>
