<template>
  <article class="col-12 py-5 border-bottom">
    <StoCscResultItemHeaderTop
      v-bind:name="item.productData.name"
      v-bind:item="item"
    />
    <section class="row">
      <StoCscResultItemAside v-bind:image="item.image" />
      <div class="col-md-1 col-0"></div>
      <StoCscResultItemInfos v-bind:productData="item.productData" />
    </section>
    <StoCscResultItemHeaderMiddle
      v-bind:shorthand="item.productData.shorthand"
    />
    <StoCscResultItemStructureComposition
      v-bind:image="item.image"
      v-bind:item="item"
      @show-lightbox="handleShowLightbox"
    />
    <StoCscResultItemHeaderBottom />
    <section class="row d-lg-none">
      <StoCscResultItemComposition v-bind:item="item" />
    </section>
  </article>
</template>

<script>
import StoCscResultItemHeaderTop from "./HeaderTop.vue";
import StoCscResultItemHeaderMiddle from "./HeaderMiddle.vue";
import StoCscResultItemHeaderBottom from "./HeaderBottom.vue";
import StoCscResultItemAside from "./Aside.vue";
import StoCscResultItemComposition from "./Composition.vue";
import StoCscResultItemStructureComposition from "./StructureComposition.vue";
import StoCscResultItemInfos from "./Infos.vue";

export default {
  name: "StoCscResultItemGrid",
  props: {
    item: Object,
    index: Number,
  },
  components: {
    StoCscResultItemHeaderTop,
    StoCscResultItemHeaderMiddle,
    StoCscResultItemHeaderBottom,
    StoCscResultItemAside,
    StoCscResultItemComposition,
    StoCscResultItemStructureComposition,
    StoCscResultItemInfos,
  },
  methods: {
    handleShowLightbox(imageSrc) {
      this.$emit('show-lightbox', imageSrc);
    },
  },
};
</script>