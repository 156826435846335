var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['row mx-0', _vm.addContainerClasses]},[_c('div',{staticClass:"col-12 pb-3 px-0 font-weight-bold"},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.title))])])]),_c('div',{staticClass:"col-12 px-0"},[_c('div',{staticClass:"row"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"col-12 col-md-6 col-lg-3 text-center d-flex flex-column justify-content-end align-items-center pb-md-3"},[_c('img',{staticClass:"w-100 d-none d-md-block mb-3 selector-image",class:[
            _vm.filterUiState('systemGroup', item.value),
            { 'hover-effect': _vm.hoveredItem === index },
          ],staticStyle:{"cursor":"pointer"},attrs:{"src":'/assets/images/keyvisuals/' + item.img},on:{"click":function($event){return _vm.selectValue(item.value)},"mouseover":function($event){return _vm.hoverItem(index)},"mouseleave":_vm.unhoverItem}}),_c('button',{class:[
            'btn btn-sm btn-outline-filter w-100',
            _vm.filterUiState('systemGroup', item.value),
            { 'hovered-style': _vm.hoveredItem === index },
          ],staticStyle:{"margin-right":"0 !important"},attrs:{"type":"button"},on:{"click":function($event){return _vm.selectValue(item.value)},"mouseover":function($event){return _vm.hoverItem(index)},"mouseleave":_vm.unhoverItem}},[_c('span',[_vm._v(_vm._s(item.title))])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }