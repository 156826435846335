<template>
  <section class="result-infos col-12 col-md-6 py-4">
    <div class="row">
      <div class="col-12 col-md-6" v-for="(item, index) in items" :key="index">
        <div class="row">
          <div class="col-12 font-weight-bold" v-html="item.title" />
          <ul class="col-12 py-1">
            <li
              v-for="(value, index) in item.value"
              :key="index"
              v-html="value"
            />
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 col-xl-3 pb-3 font-weight-bold">
        <div>
          <span class="font-weight-bold">{{ processingTime.title }}</span>
        </div>
      </div>
      <div class="col-12 col-md-8 col-xl-9" v-html="processingTime.value" />
    </div>
    <div class="row">
      <div class="col-12 col-md-4 col-xl-3 pb-3 font-weight-bold">
        <div>
          <span class="font-weight-bold">{{ priceLevel.title }}</span>
        </div>
      </div>
      <div class="col-12 col-md-8 col-xl-9" v-html="priceLevel.value" />
    </div>
  </section>
</template>

<script>
import { getString } from "../../../language";

const getOutput = (productData, name) => {
  if (name == "advantages") {
    return productData.advantages;
  } else if (name == "technical") {
    return productData.technical;
  } else {
    return [];
  }
};

export default {
  name: "StoCscResultItemInfos",
  props: {
    productData: Object,
  },
  data: function () {
    return {};
  },
  computed: {
    items() {
      return [
        {
          title: getString("result.infos.advantages"),
          value: getOutput(this.productData, "advantages"),
        },
        {
          title: getString("result.infos.technical"),
          value: getOutput(this.productData, "technical"),
        },
      ];
    },
    processingTime() {
      let imgNumber = 1;
      for (
        let i = 0;
        i < this.productData.construction.composition.length;
        i++
      ) {
        if (
          this.productData.construction.composition[i].type == "processingTime"
        ) {
          imgNumber =
            this.productData.construction.composition[i].value.slice(-1);
        }
      }
      return {
        title: getString("result.infos.processingTime"),
        value:
          '<img style="height: 24px; width: auto; margin-left: 9px;" src="/assets/images/icons/ui/verarbeitungszeit-' +
          imgNumber +
          '.svg"/>',
      };
    },
    priceLevel() {
      let imgNumber = 1;
      for (
        let i = 0;
        i < this.productData.construction.composition.length;
        i++
      ) {
        if (this.productData.construction.composition[i].type == "priceLevel") {
          imgNumber =
            this.productData.construction.composition[i].value.slice(-1);
        }
      }
      return {
        title: getString("result.infos.priceLevel"),
        value:
          '<img style="height: 24px; width: auto" src="/assets/images/icons/ui/preisniveau-' +
          imgNumber +
          '.svg"/>',
      };
    },
  },
};
</script>
