<template>
  <div class="col-12">
    <div class="results-row row px-0 mx-0 pb-5 pt-lg-0">
      <StoCscResultHeader v-bind:listLength="itemList.length" />
      <StoCscResultItemGrid
        v-for="(item, index) in itemList"
        :key="index"
        v-bind:item="item"
        v-bind:index="index"
        @show-lightbox="handleShowLightbox"
      />
    </div>
    <div
      class="modal fade"
      id="lightboxModal"
      tabindex="-1"
      aria-labelledby="lightboxModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="custom-close"
              data-dismiss="modal"
              aria-label="Close"
              style="border: none; background: transparent"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <img :src="modalImageSrc" class="img-fluid" alt="Label Preview" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import StoCscResultHeader from "./Header.vue";
import StoCscResultItemGrid from "./Item/Grid.vue";

export default {
  name: "StoCscResultGrid",
  components: {
    StoCscResultHeader,
    StoCscResultItemGrid,
  },
  data() {
    return {
      modalImageSrc: "",
    };
  },

  computed: {
    ...mapGetters({
      itemList: "results/items",
    }),
  },
  methods: {
    handleShowLightbox(imageSrc) {
      this.modalImageSrc = imageSrc;
      $("#lightboxModal").modal("show");
    },
  },
};
</script>
