var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row composition-row border-bottom py-1",staticStyle:{"position":"relative","align-items":"center"}},[_c('div',{ref:"toggleElement",staticClass:"col-6 structure-product pr-0 d-flex custom-select",staticStyle:{"cursor":"pointer"},domProps:{"innerHTML":_vm._s(
      _vm.getProductName(
        _vm.structure.selectedProduct,
        _vm.structure.products,
        true,
        _vm.structure
      )
    )},on:{"click":_vm.toggleDropdown}}),_c('div',{staticClass:"col-3 structure-consumption pr-0 d-flex",staticStyle:{"justify-content":"flex-end"},domProps:{"innerHTML":_vm._s(_vm.getProductConsumption(_vm.structure.selectedProduct))}}),_c('div',{staticClass:"col-3 text-left pr-0 structure-unit d-flex",domProps:{"innerHTML":_vm._s(_vm.getProductUnit(_vm.structure.selectedProduct))}}),(_vm.isOpen)?_c('div',{ref:"dropdown",staticClass:"options"},_vm._l((_vm.filteredProducts),function(product,index){return _c('div',{key:index,staticClass:"option row mr-0 py-1 d-flex",staticStyle:{"align-items":"center","cursor":"pointer"},on:{"click":function($event){return _vm.selectOption(product)}}},[_c('div',{staticClass:"col-6 structure-product pr-0 d-flex",staticStyle:{"padding-left":"30px"},domProps:{"innerHTML":_vm._s(_vm.getProductName(product, _vm.structure.products))}}),_c('div',{staticClass:"col-3 structure-consumption pr-0 d-flex",staticStyle:{"justify-content":"flex-end"},domProps:{"innerHTML":_vm._s(_vm.getProductConsumption(product))}}),_c('div',{staticClass:"col-3 text-left pr-0 structure-unit d-flex",domProps:{"innerHTML":_vm._s(_vm.getProductUnit(product))}})])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }