<template>
  <header class="row font-weight-bold bg-gray">
    <div class="col-9 py-1 pr-0" v-html="name" />
    <div class="col-3 pr-0">
      <span
        role="button"
        v-on:click="onClickPDF(item.productData.name)"
        target="_blank"
        class="btn btn-sm btn-black float-right"
      >
        {{ buttonPDF }}
      </span>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { getString } from "../../../language";
import { products, labels } from "@/store/filter/values";

export default {
  name: "StoCscResultItemHeaderTop",
  props: {
    name: String,
    item: Object,
  },
  data: function () {
    return {
      buttonPDF: getString("result.buttonPDF"),
      allProducts: products,
      allLabels: labels,
    };
  },
  computed: {
    ...mapGetters({
      formData: "stats/formData",
      formState: "stats/formState",
    }),
  },
  methods: {
    onClickPDF: function (name) {
      this.$gtm.trackEvent({
        event: "clickElement",
        category: "pdf",
        action: "download",
        label: name + "",
        value: null,
      });

      this.$store.dispatch("filter/downloadPdf", {
        item: this.item,
        shorthand: this.item.productData.shorthand,
        projectName: this.formData.name,
        products: this.allProducts,
        labels: this.allLabels,
        size: this.formData.size,
        shade: this.formData.shade,
      });
    },
  },
};
</script>
