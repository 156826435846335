<template>
  <section class="row" style="margin-right: -15px; margin-left: -15px">
    <aside class="col-12 col-lg-3 pr-0 col-md-6 py-4">
      <img class="w-100" v-bind:src="'/assets/images/structures/' + image" />
    </aside>
    <div
      class="composition-wrapper composition-wrapper-structure col-12 col-lg-9 col-md-6 py-4"
    >
      <div
        class="row composition-row border-bottom"
        v-for="(structure, index) in item.productData.systemStructure"
        :key="index"
      >
        <div class="col-12 col-lg-4 pr-0 py-1 structure-title">
          <span class="font-weight-bold" style="padding-right: 5px"
            >{{ structure.layer }}{{ " " }}</span
          >
          <span class="composition-material-structure">{{
            getLocalizedString("systemStructure." + structure.type)
          }}</span>
        </div>

        <div
          v-if="structure.products.length == 1"
          class="d-none d-lg-block pr-0 col-8 py-1 structure-inner-row"
        >
          <div class="row mr-0">
            <div
              class="col-4 structure-product pr-0 d-flex"
              v-html="
                getProductName(structure.selectedProduct, structure.products)
              "
            />
            <div
              class="col-3 structure-labels pr-0 d-flex"
              style="overflow: visible; z-index: 2"
            >
              <img
                v-for="label in getProductLabels(structure)"
                :key="label.src"
                :src="label.src"
                @click="showLightbox(label.src)"
                :alt="label.title"
                style="height: 26px; width: auto; cursor: pointer"
              />
            </div>
            <div
              class="col-3 structure-consumption pr-0 d-flex"
              style="justify-content: flex-end"
              v-html="getProductConsumption(structure.selectedProduct)"
            ></div>
            <div
              class="col-2 text-left pr-0 structure-unit d-flex"
              v-html="getProductUnit(structure.selectedProduct)"
            ></div>
          </div>
        </div>
        <CustomSelect
          v-else
          :structure="structure"
          :item="item"
          @show-lightbox="showLightbox"
        ></CustomSelect>
      </div>
    </div>
  </section>
</template>

<script>
import CustomSelect from "./CustomSelect";
import { mapGetters } from "vuex";
import { getString } from "../../../language";
import { products, labels } from "@/store/filter/values";

export default {
  name: "StoCscResultItemStructureComposition",
  props: {
    item: Object,
    image: String,
  },
  data: function () {
    return {
      allProducts: products,
      allLabels: labels,
    };
  },
  components: {
    CustomSelect,
  },
  computed: {
    ...mapGetters({
      formData: "stats/formData",
    }),
  },
  methods: {
    getLocalizedString(key) {
      return getString(key);
    },
    getProductName(product, products) {
      let productName = "";
      if (this.allProducts[product]) {
        productName = this.allProducts[product].title;
        if (products.length > 1) {
          if (this.allProducts[product].fineness !== "") {
            productName += ` ${this.allProducts[product].fineness}`;
          } else {
            let otherProducts = products.filter((p) => p !== product);
            let otherProductNames = otherProducts.map(
              (p) => this.allProducts[p].title
            );
            if (otherProductNames.includes(productName)) {
              productName += ` (${this.allProducts[product].containerType})`;
            }
          }
        }
      }
      return productName;
    },
    showLightbox(imageSrc) {
      this.$emit("show-lightbox", imageSrc);
    },
    getProductLabels(structure) {
      let product = structure.selectedProduct;
      if (product === 0 || !this.allProducts[product]) {
        return [];
      }
      return this.allProducts[product].labels.map((label) => {
        let productLabel = this.allLabels[label];
        return {
          src: `/assets/images/icons/umweltlabels/${productLabel.image}`,
          title: productLabel.title,
        };
      });
    },
    getProductConsumption(product) {
      if (product === 0) {
        return "";
      }
      if (this.allProducts[product]) {
        return (
          this.$store.getters["stats/formData"].size *
          this.allProducts[product].consumption
        ).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      return "";
    },
    getProductUnit(product) {
      if (product === 0) {
        return "";
      }
      if (this.allProducts[product]) {
        return this.allProducts[product].unit;
      }
      return "";
    },
    selectProduct(item, structure, product) {
      this.$store.dispatch("products/setValue", {
        item: item,
        structure: structure,
        product: product,
      });
    },
  },
};
</script>

<style scoped>
.composition-row .font-weight-bold {
  color: black !important;
}

.composition-row .composition-material-structure {
  color: #767676;
}

.composition-row > div {
  display: flex;
  align-items: center;
}
</style>