<template>
  <div
    class="row composition-row border-bottom py-1"
    style="position: relative; align-items: center"
  >
    <div
      class="col-6 structure-product pr-0 d-flex custom-select"
      style="cursor: pointer"
      @click="toggleDropdown"
      ref="toggleElement"
      v-html="
        getProductName(
          structure.selectedProduct,
          structure.products,
          true,
          structure
        )
      "
    />
    <div
      class="col-3 structure-consumption pr-0 d-flex"
      style="justify-content: flex-end"
      v-html="getProductConsumption(structure.selectedProduct)"
    ></div>
    <div
      class="col-3 text-left pr-0 structure-unit d-flex"
      v-html="getProductUnit(structure.selectedProduct)"
    ></div>
    <div class="options" v-if="isOpen" ref="dropdown">
      <div
        class="option row mr-0 py-1 d-flex"
        v-for="(product, index) in filteredProducts"
        :key="index"
        @click="selectOption(product)"
        style="align-items: center; cursor: pointer"
      >
        <div
          class="col-6 structure-product pr-0 d-flex"
          style="padding-left: 30px"
          v-html="getProductName(product, structure.products)"
        />
        <div
          class="col-3 structure-consumption pr-0 d-flex"
          style="justify-content: flex-end"
          v-html="getProductConsumption(product)"
        ></div>
        <div
          class="col-3 text-left pr-0 structure-unit d-flex"
          v-html="getProductUnit(product)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { products } from "@/store/filter/values";
export default {
  props: ["structure", "item"],
  data() {
    return {
      isOpen: false,
      allProducts: products,
    };
  },
  computed: {
    ...mapGetters({
      formData: "stats/formData",
    }),
    filteredProducts() {
      return this.structure.products.filter(
        (product) => product !== this.structure.selectedProduct
      );
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(product) {
      this.$store.dispatch("products/setValue", {
        item: this.item,
        structure: this.structure,
        product: product,
      });
      this.isOpen = false;
    },
    getProductName(
      product,
      products,
      outsideOptions = false,
      structure = null
    ) {
      let productName = "";
      if (this.allProducts[product]) {
        productName = this.allProducts[product].title;
        if (products.length > 1) {
          if (this.allProducts[product].fineness !== "") {
            productName += ` ${this.allProducts[product].fineness}`;
          } else {
            let otherProducts = products.filter((p) => p !== product);
            let otherProductNames = otherProducts.map(
              (p) => this.allProducts[p].title
            );
            if (otherProductNames.includes(productName)) {
              productName += ` (${this.allProducts[product].containerType})`;
            }
          }
        }
      }
      if (outsideOptions && structure) {
        productName =
          '<span class="font-weight-bold" style="padding-right: 5px">' +
          structure.layer +
          ' </span><span class="composition-material-structure">' +
          productName;
      }
      if (outsideOptions && structure) {
        productName += "</span>";
      }
      return productName;
    },
    showLightbox(imageSrc) {
      this.$emit("show-lightbox", imageSrc);
    },
    getProductConsumption(product) {
      if (product === 0) {
        return "";
      }
      if (this.allProducts[product]) {
        return (
          this.$store.getters["stats/formData"].size *
          this.allProducts[product].consumption
        ).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      return "";
    },
    getProductUnit(product) {
      if (product === 0) {
        return "";
      }
      if (this.allProducts[product]) {
        return this.allProducts[product].unit;
      }
      return "";
    },
    closeDropdownIfOutside(event) {
      if (
        this.isOpen &&
        !this.$refs.toggleElement.contains(event.target) &&
        !this.$refs.dropdown.contains(event.target)
      ) {
        this.isOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdownIfOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropdownIfOutside);
  },
};
</script>

<style scoped>
.custom-select {
  position: relative;
  cursor: pointer;
  padding-right: 15px !important;
}
.custom-select::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: 5px solid transparent;
  border-top-color: #767676;
}

.options {
  position: absolute;
  background-color: white;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  cursor: pointer;
  color: #767676;
  margin-top: 1px;
  padding-left: 15px;
  left: 0;
  top: 100%;
}

.option:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
}

.option:hover {
  background-color: #f0f0f0;
}
</style>
