
const state = () => ({

  _callstack: [],

  systemGroup: { values: [] },
  room: { values: [] },
  environmentHealth: { values: [] },
  resilience: { values: [] },
  designSurface: { values: [] },
  processing: { values: [] },
  processingTime: { values: [] },
  priceLevel: { values: [] }
})

export default state
