<template>
  <div>
    <span
      class="popover-trigger font-weight-bold"
      @click="show(info)"
      @mouseleave="hide(info)"
      @mouseover="show(info)"
      >{{ title }}*</span
    >
    <div
      :data-popover="info"
      class="popover fade bs-popover-bottom d-none"
      role="tooltip"
      x-placement="bottom"
    >
      <div class="arrow"></div>
      <h3 class="popover-header"></h3>
      <div class="popover-body">
        <p v-html="getInfoText()" />
        <div
          data-popover-close="system"
          class="d-block d-md-none font-weight-bold text-center align-middle popover-close"
          @click.stop="hide(info)"
        >
          X
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getString } from "../language";

export default {
  name: "Popover",
  props: {
    info: String,
    title: String,
  },

  methods: {
    getInfoText: function () {
      return getString("strings.info-texts." + this.info);
    },
    show: function (object) {
      document
        .querySelector('[data-popover="' + object + '"]')
        .classList.remove("d-none");
    },
    hide: function (object) {
      document
        .querySelector('[data-popover="' + object + '"]')
        .classList.add("d-none");
    },
  },
};
</script>
<style scoped>
.popover {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 50vw;
  max-width: unset;
}
@media only screen and (max-width: 600px) {
  .popover {
    width: 80vw;
  }
}
</style>
