
import S1 from './s1'
import S2 from './s2'
import S3 from './s3'
import S4 from './s4'
import O1 from './o1'
import O2 from './o2'
import O3 from './o3'
import O4 from './o4'
import R1 from './r1'
import R2 from './r2'
import R3 from './r3'
import R4 from './r4'
import P1 from './p1'
import P2 from './p2'
import P3 from './p3'
import P4 from './p4'
import mutations from './mutations'
import actions from './actions'

const state = () => ({
  items: {
    S1,
    S2,
    S3,
    S4,
    O1,
    O2,
    O3,
    O4,
    R1,
    R2,
    R3,
    R4,
    P1,
    P2,
    P3,
    P4
  }
})

const getters = {
  items: state => state.items
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
