<template>
  <div :class="['row mx-0', addContainerClasses]">
    <div class="col-12 pb-3 px-0 font-weight-bold">
      <Popover info="room" :title="title" />
    </div>
    <div class="col-12 px-0">
      <div class="row">
        <div
          class="col-6 col-lg-2 text-center d-flex flex-column justify-content-end align-items-center pb-md-3"
          v-for="(item, index) in items"
          :key="index"
        >
          <img
            class="w-100 d-none d-md-block mb-3 selector-image"
            :class="[
              filterUiState('room', item.value),
              { 'hover-effect': hoveredItem === index },
            ]"
            style="cursor: pointer"
            v-bind:src="'/assets/images/rooms/' + item.img"
            v-on:click="selectValue(item.value)"
            @mouseover="hoverItem(index)"
            @mouseleave="unhoverItem"
          />
          <button
            style="margin-right: 0 !important"
            type="button"
            :class="[
              'btn btn-sm btn-outline-filter w-100',
              filterUiState('room', item.value),
              { 'hovered-style': hoveredItem === index },
            ]"
            v-on:click="selectValue(item.value)"
            @mouseover="hoverItem(index)"
            @mouseleave="unhoverItem"
          >
            <span>{{ item.title }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { trackCallstack } from "../../../helper/tracker";
import Popover from "../../Popover.vue";

export default {
  name: "StoCscFilterSectionRoom",
  components: {
    Popover,
  },
  props: {
    addContainerClasses: String,
  },
  data: function () {
    return {
      title: "Räume",
      items: [
        { title: "Wohnräume", value: "room-1", img: "wohnraeume.jpg" },
        { title: "Bad", value: "room-2", img: "bad.jpg" },
        { title: "Küche", value: "room-3", img: "kuechen.jpg" },
        { title: "Flur", value: "room-4", img: "flur.jpg" },
        { title: "Keller", value: "room-5", img: "keller.jpeg" },
        { title: "Garagen", value: "room-6", img: "garage.jpeg" },
      ],
      hoveredItem: null,
    };
  },
  computed: {
    ...mapGetters({
      filterUiState: "filter/uiState",
      filterState: "filter/state",
    }),
  },
  methods: {
    selectValue: function (value) {
      let title = "";
      const result = this.items.filter((item) => item.value === value);
      if (result.length > 0) title = result[0].title;

      const uiState = this.filterUiState("room", value);

      this.$gtm.trackEvent({
        event: "filter",
        category: this.title,
        action:
          uiState === "pending"
            ? "hinzufügen"
            : uiState === "active"
            ? "reaktivieren"
            : "aktivieren",
        label: title,
        value: value,
      });

      this.$store
        .dispatch("filter/setValue", {
          name: "room",
          title: this.title + " " + title,
          value: value,
        })
        .then(() => trackCallstack(this.$gtm, this.filterState._callstack));
    },
    hoverItem(index) {
      this.hoveredItem = index;
    },
    unhoverItem() {
      this.hoveredItem = null;
    },
  },
};
</script>

<style scoped>
.hovered-style {
  background-color: #ffcc00;
  border-color: #ffcc00;
}
.selector-image {
  transition: box-shadow 0.15s ease-in-out, width 0.15s ease-in-out,
    height 0.15s ease-in-out;
}
.hover-effect,
.selector-image.active,
.selector-image.pending,
.selector-image:hover {
  box-shadow: 0 0 0 2px #ffcc00;
  width: calc(100% - 4px) !important;
}
</style>