<template>
  <div>
    <h4>{{ headline }}</h4>
    <h5 v-show="stateFilterLength > 0">{{ choosenFilter }}</h5>
    <ul>
      <li
        v-for="(item, name) in stateFilter"
        :key="item.id"
        v-html="format(item, name)"
      />
    </ul>
    <ul>
      <li v-for="item in resultItems" :key="item.id">
        <span
          role="button"
          v-on:click="onClickPDF(item, item.productData.name)"
          target="_blank"
          >{{ item.productData.name }} - {{ buttonPDF }}</span
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  getString,
  getSectionTitle,
  getSectionValueTitle,
} from "../../language";

import { products, labels } from "@/store/filter/values";

export default {
  name: "StoCscContactSummary",
  data: function () {
    return {
      headline: getString("contact.summary.headline"),
      choosenFilter: getString("contact.summary.choosenFilter"),
      buttonPDF: getString("result.buttonPDF"),
      allProducts: products,
      allLabels: labels,
    };
  },
  computed: {
    ...mapGetters({
      stateFilter: "filter/stateFilter",
      resultItems: "results/items",
      formData: "contact/formData",
      statsFormData: "stats/formData",
    }),
    stateFilterLength: function () {
      return Object.keys(this.stateFilter).length;
    },
  },
  methods: {
    format(item, name) {
      return (
        getSectionTitle(name) +
        ": " +
        getSectionValueTitle(name, item.values[0])
      );
    },
    onClickPDF: function (item, name) {
      this.$gtm.trackEvent({
        event: "clickElement",
        category: "pdf",
        action: "download",
        label: name + "",
        value: null,
      });
      this.$store.dispatch("filter/downloadPdf", {
        item: item,
        shorthand: item.productData.shorthand,
        projectName: this.statsFormData.name,
        products: this.allProducts,
        labels: this.allLabels,
        size: this.statsFormData.size,
        shade: this.statsFormData.shade,
      });
    },
  },
};
</script>
