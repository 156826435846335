export default {
  image: 'r4.jpg',
  productData: {
    name: 'Robustes System R4',
    shorthand: "R4",
    advantages: [
      "Robust",
      "Kreative Optik",
      "Reinigungsfähig",
      "Designvielfalt"
    ],
    technical: [
      "Feuchtraum WO-I",
      "Reinigungsfähig",
      "Volle Farbtonauswahl",
      "Strukturierte Flächen",
      "Glanzgrad seidenmatt/seidenglänzend",
      "Erhöhte optische Ansprüche"
    ],
    systemStructure: [
      {
        type: 'subsurface',
        layer: 1,
        products: [0],
        selectedProduct: 0,
        changedProduct: false
      },
      {
        type: 'joints',
        layer: 2,
        products: [1],
        selectedProduct: 1,
        changedProduct: false
      },
      {
        type: 'surfaceFilling',
        layer: 3,
        products: [9],
        selectedProduct: 9,
        changedProduct: false
      },
      {
        type: 'firstCoat',
        layer: 4,
        products: [3],
        selectedProduct: 3,
        changedProduct: false
      },
      {
        type: 'glue',
        layer: 5,
        products: [40],
        selectedProduct: 40,
        changedProduct: false
      },
      {
        type: 'wallCovering',
        layer: 6,
        products: [42],
        selectedProduct: 42,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer1',
        layer: 7,
        products: [43],
        selectedProduct: 43,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer2',
        layer: 7,
        products: [43],
        selectedProduct: 43,
        changedProduct: false
      }
    ],
    construction: {
      composition: [
        {
          title: { de: 'Systemgruppen' },
          output: 'Robustes System',
          type: 'systemGroup',
          value: 'systemgroup-3'
        },
        {
          title: { de: 'Räume' },
          output: 'Wohnräume',
          type: 'room',
          value: 'room-1'
        },
        {
          title: { de: 'Räume' },
          output: 'Bad',
          type: 'room',
          value: 'room-2'
        },
        {
          title: { de: 'Räume' },
          output: 'Küche',
          type: 'room',
          value: 'room-3'
        },
        {
          title: { de: 'Räume' },
          output: 'Flur',
          type: 'room',
          value: 'room-4'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Schadstoffgeprüft',
          type: 'environmentHealth',
          value: 'environmenthealth-1'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Öko Tex',
          type: 'environmentHealth', 
          value: 'environmenthealth-5'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Streiflichtunempfindlich',
          type: 'resilience', 
          value: 'resilience-2'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Stark mechanisch',
          type: 'resilience', 
          value: 'resilience-3'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Reinigungsfähig',
          type: 'resilience', 
          value: 'resilience-4'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Risssicher',
          type: 'resilience', 
          value: 'resilience-6'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Langlebig',
          type: 'resilience', 
          value: 'resilience-7'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Satin',
          type: 'designSurface', 
          value: 'designsurface-3'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Strukturiert',
          type: 'designSurface', 
          value: 'designsurface-7'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Erhöhte optische Ansprüche',
          type: 'designSurface', 
          value: 'designsurface-8'
        },
        {
          title: { de: 'Verarbeitungszeit' },
          output: 'Stufe 3',
          type: 'processingTime', 
          value: 'processingtime-3'
        },
        {
          title: { de: 'Preisniveau' },
          output: '€€',
          type: 'priceLevel', 
          value: 'pricelevel-2'
        }
      ]
    }
  }
}
