<template>
  <div :class="['row filter-row mx-0 mb-3', addContainerClasses]">
    <div class="col-12 col-md-4 col-xl-3 pb-3 font-weight-bold">
      <div>
        <span class="font-weight-bold">{{ title }}</span>
      </div>
    </div>
    <div class="col-12 col-md-8 col-xl-9">
      <button
        type="button"
        v-for="(item, index) in items"
        :key="index"
        :class="[
          'btn btn-sm btn-outline-filter image money',
          filterUiState('priceLevel', item.value),
        ]"
        v-on:click="selectValue(item.value)"
      >
        <img
          style="height: 24px; width: auto"
          v-bind:src="'/assets/images/icons/ui/' + item.icon"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { trackCallstack } from "../../../helper/tracker";
import Popover from "../../Popover.vue";

export default {
  name: "StoCscFilterSectionPriceLevel",
  components: {
    Popover,
  },
  props: {
    addContainerClasses: String,
  },
  data: function () {
    return {
      title: "Preisniveau",
      items: [
        {
          title: "€",
          value: "pricelevel-1",
          icon: "preisniveau-1.svg",
        },
        {
          title: "€€",
          value: "pricelevel-2",
          icon: "preisniveau-2.svg",
        },
        {
          title: "€€€",
          value: "pricelevel-3",
          icon: "preisniveau-3.svg",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      filterUiState: "filter/uiState",
      filterState: "filter/state",
    }),
  },
  methods: {
    showPopover(infotitle) {
      const popover = document.querySelector(`[data-popover="${infotitle}"]`);
      const trigger = document.querySelector(`[data-trigger="${infotitle}"]`);
      const container = trigger.closest(".col-12.col-md-8.col-xl-9");
      if (popover && trigger && container) {
        const triggerRect = trigger.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        popover.style.top = `${triggerRect.bottom - containerRect.top}px`;
        popover.classList.remove("d-none");

        const arrow = popover.querySelector(".arrow");
        if (arrow) {
          arrow.style.left = `${
            triggerRect.left +
            triggerRect.width / 2 -
            containerRect.left -
            arrow.offsetWidth / 2
          }px`;
        }
      }
    },
    hidePopover(infotitle) {
      const popover = document.querySelector(`[data-popover="${infotitle}"]`);
      if (popover) {
        popover.classList.add("d-none");
      }
    },
    selectValue: function (value) {
      let title = "";
      const result = this.items.filter((item) => item.value === value);
      if (result.length > 0) title = result[0].title;

      const uiState = this.filterUiState("priceLevel", value);

      this.$gtm.trackEvent({
        event: "filter",
        category: this.title,
        action:
          uiState === "pending"
            ? "hinzufügen"
            : uiState === "active"
            ? "reaktivieren"
            : "aktivieren",
        label: title,
        value: value,
      });

      this.$store
        .dispatch("filter/setValue", {
          name: "priceLevel",
          title: this.title + " " + title,
          value: value,
        })
        .then(() => trackCallstack(this.$gtm, this.filterState._callstack));
    },
  },
};
</script>
