<template>
  <header class="row font-weight-bold bg-gray d-lg-none">
    <div class="col-6 py-1 pr-0" v-html="orderList" />
    <div
      class="col-3 py-1 pr-0 d-flex"
      style="justify-content: flex-end; align-items: center; font-size: 0.8rem"
      v-html="consumption"
    />
    <div
      class="col-3 py-1 pr-0 d-flex"
      style="
        justify-content: flex-start;
        align-items: center;
        font-size: 0.8rem;
      "
      v-html="unit"
    />
  </header>
</template>

<script>
import { getString } from "../../../language";

export default {
  name: "StoCscResultItemHeaderBottom",
  data: function () {
    return {
      orderList: getString("result.orderList.title"),
      consumption: getString("result.orderList.consumption"),
      unit: getString("result.orderList.unit"),
    };
  },
};
</script>
