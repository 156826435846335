export default {
  image: 'oe1.jpg',
  productData: {
    name: 'Ökologisches System Ö1',
    shorthand: "Ö1",
    advantages: [
      "Ökologisch",
      "Konservierungsmittelfreie Schlussbeschichtung",
      "Risssicher",
      "Einfache Verarbeitung"
    ],
    technical: [
      "Allergiker-geeignet",
      "Strukturierte Flächen",
      "Glanzgrad stumpfmatt",
      "TÜV",
      "frei von KM (Schlussbeschichtung)"
    ],
    systemStructure: [
      {
        type: 'subsurface',
        layer: 1,
        products: [0],
        selectedProduct: 0,
        changedProduct: false
      },
      {
        type: 'joints',
        layer: 2,
        products: [1],
        selectedProduct: 1,
        changedProduct: false
      },
      {
        type: 'firstCoat',
        layer: 3,
        products: [10],
        selectedProduct: 10,
        changedProduct: false
      },
      {
        type: 'glue',
        layer: 4,
        products: [4],
        selectedProduct: 4,
        changedProduct: false
      },
      {
        type: 'wallCovering',
        layer: 5,
        products: [20, 21],
        selectedProduct: 20,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer1',
        layer: 6,
        products: [22],
        selectedProduct: 22,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer2',
        layer: 6,
        products: [22],
        selectedProduct: 22,
        changedProduct: false
      }
    ],
    construction: {
      composition: [
        {
          title: { de: 'Systemgruppen' },
          output: 'Ökologisches System',
          type: 'systemGroup',
          value: 'systemgroup-2'
        },
        {
          title: { de: 'Räume' },
          output: 'Wohnräume',
          type: 'room',
          value: 'room-1'
        },
        {
          title: { de: 'Räume' },
          output: 'Flur',
          type: 'room',
          value: 'room-4'
        },
        {
          title: { de: 'Räume' },
          output: 'Keller',
          type: 'room',
          value: 'room-5'
        },
        {
          title: { de: 'Räume' },
          output: 'Garagen',
          type: 'room',
          value: 'room-6'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Schadstoffgeprüft',
          type: 'environmentHealth',
          value: 'environmenthealth-1'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'TÜV',
          type: 'environmentHealth', 
          value: 'environmenthealth-2'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'SentinelHaus',
          type: 'environmentHealth', 
          value: 'environmenthealth-4'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Konservierungsmittelfrei (Farbe/Putz)',
          type: 'environmentHealth', 
          value: 'environmenthealth-7'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Streiflichtunempfindlich',
          type: 'resilience', 
          value: 'resilience-2'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Risssicher',
          type: 'resilience', 
          value: 'resilience-6'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Matt',
          type: 'designSurface', 
          value: 'designsurface-1'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Strukturiert',
          type: 'designSurface', 
          value: 'designsurface-7'
        },
        {
          title: { de: 'Verarbeitung' },
          output: 'Einfach',
          type: 'processing', 
          value: 'processing-1'
        },
        {
          title: { de: 'Verarbeitung' },
          output: 'Rückbaufähig',
          type: 'processing', 
          value: 'processing-2'
        },
        {
          title: { de: 'Verarbeitung' },
          output: 'Schnell',
          type: 'processing', 
          value: 'processing-4'
        },
        {
          title: { de: 'Verarbeitungszeit' },
          output: 'Stufe 1',
          type: 'processingTime', 
          value: 'processingtime-1'
        },
        {
          title: { de: 'Preisniveau' },
          output: '€',
          type: 'priceLevel', 
          value: 'pricelevel-1'
        }
      ]
    }
  }
}
