export default {
  image: 'p3.jpg',
  productData: {
    name: 'Premiumsystem P3',
    shorthand: "P3",
    advantages: [
      "Optisches Highlight",
      "Kreative Optik",
      "Feuchteregulierend",
      "Langlebig"
    ],
    technical: [
      "Schimmelschutz",
      "Feuchtemanagement",
      "Feuchtraum WO-I",
      "Glatte Flächen",
      "Strukturierte Flächen",
      "Glanzgrad stumpfmatt",
      "Erhöhte optische Ansprüche",
      "TÜV",
      "frei von KM (Schlussbeschichtung)"
    ],
    systemStructure: [
      {
        type: 'subsurface',
        layer: 1,
        products: [0],
        selectedProduct: 0,
        changedProduct: false
      },
      {
        type: 'joints',
        layer: 2,
        products: [1],
        selectedProduct: 1,
        changedProduct: false
      },
      {
        type: 'surfaceFilling',
        layer: 3,
        products: [51, 52],
        selectedProduct: 51,
        changedProduct: false
      },
      {
        type: 'firstCoat',
        layer: 4,
        products: [10],
        selectedProduct: 10,
        changedProduct: false
      },
      {
        type: 'intermediateCoat',
        layer: 5,
        products: [46],
        selectedProduct: 46,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer1',
        layer: 6,
        products: [47],
        selectedProduct: 47,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer2',
        layer: 6,
        products: [47],
        selectedProduct: 47,
        changedProduct: false
      }
    ],
    construction: {
      composition: [
        {
          title: { de: 'Systemgruppen' },
          output: 'Premiumsystem',
          type: 'systemGroup',
          value: 'systemgroup-4'
        },
        {
          title: { de: 'Räume' },
          output: 'Wohnräume',
          type: 'room',
          value: 'room-1'
        },
        {
          title: { de: 'Räume' },
          output: 'Bad',
          type: 'room',
          value: 'room-2'
        },
        {
          title: { de: 'Räume' },
          output: 'Küche',
          type: 'room',
          value: 'room-3'
        },
        {
          title: { de: 'Räume' },
          output: 'Flur',
          type: 'room',
          value: 'room-4'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Schadstoffgeprüft',
          type: 'environmentHealth',
          value: 'environmenthealth-1'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'TÜV',
          type: 'environmentHealth', 
          value: 'environmenthealth-2'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Konservierungsmittelfrei (Farbe/Putz)',
          type: 'environmentHealth', 
          value: 'environmenthealth-7'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Stark mechanisch',
          type: 'resilience', 
          value: 'resilience-3'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Langlebig',
          type: 'resilience', 
          value: 'resilience-7'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Matt',
          type: 'designSurface', 
          value: 'designsurface-1'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Glatt',
          type: 'designSurface', 
          value: 'designsurface-6'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Strukturiert',
          type: 'designSurface', 
          value: 'designsurface-7'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Erhöhte optische Ansprüche',
          type: 'designSurface', 
          value: 'designsurface-8'
        },
        {
          title: { de: 'Verarbeitungszeit' },
          output: 'Stufe 3',
          type: 'processingTime', 
          value: 'processingtime-3'
        },
        {
          title: { de: 'Preisniveau' },
          output: '€€€',
          type: 'priceLevel', 
          value: 'pricelevel-3'
        }
      ]
    }
  }
}
