
export default {

  resetValues (state) {
    for (const name in state.formData) {
      if (name == 'size') {
        state.formData[name] = 0
      } else if (name == 'shade') {
        state.formData[name] = 'white'
      } else {
        state.formData[name] = ""
      }
    }
  },

  displayForm (state) {
    state.displayForm = true
  },

  hideForm (state) {
    state.displayForm = false
  },

  setFormData (state, { name, value }) {
    if (typeof state.formData[name] !== 'undefined') {
      if (name === 'size' && isNaN(value)) {
        state.formData[name] = 0
      } else {
        state.formData[name] = value
      }
    }
  },

  setFormSuccess (state, { message }) {
    state.formSuccess = true
    state.formError = false
    state.formAlertMessage = message
  },

  setFormError (state, { message }) {
    state.formSuccess = false
    state.formError = true
    state.formAlertMessage = message
  },

  setFormSendState (state, sendingState) {
    state.formSending = sendingState
    if (sendingState) {
      state.formSuccess = false
      state.formError = false
    }
  }
}
