
import { validate } from './values'

export default {

  resetValues (state) {
    for (const name in state) {
      state[name].values = []
    }
    state._callstack = []
  },

  setValue (state, { name, value }) {
    if (state[name]) {
      const values = validate(name, value)
      state[name].values = values
    }
  },

  pushCallstack (state, payload) {
    state._callstack.push(payload)
  },

  clearCallstack (state) {
    state._callstack = []
  },

  setDataForPdf (state, { name, title,  value }) {
    state["pdf_" + name] = {'title' : title, 'values': value}
  },
}
