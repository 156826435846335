export default {
  image: 's4.jpg',
  productData: {
    name: 'Effizientes Standardsystem S4',
    shorthand: "S4",
    advantages: [
      "Schnelle Verarbeitung",
      "Kreative Optik",
      "Rollbare Endbeschichtung",
      "Mechanisch sehr beständig"
    ],
    technical: [
      "Feuchtraum WO-I",
      "Strukturierte Flächen",
      "Glanzgrad seidenmatt/seidenglänzend",
      "Erhöhte optische Ansprüche",
      "TÜV"
    ],
    systemStructure: [
      {
        type: 'subsurface',
        layer: 1,
        products: [0],
        selectedProduct: 0,
        changedProduct: false
      },
      {
        type: 'joints',
        layer: 2,
        products: [1],
        selectedProduct: 1,
        changedProduct: false
      },
      {
        type: 'surfaceFilling',
        layer: 3,
        products: [9],
        selectedProduct: 9,
        changedProduct: false
      },
      {
        type: 'firstCoat',
        layer: 4,
        products: [3],
        selectedProduct: 3,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer1',
        layer: 5,
        products: [17, 18, 43],
        selectedProduct: 17,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer2',
        layer: 6,
        products: [17, 18, 43],
        selectedProduct: 17,
        changedProduct: false
      },
      {
        type: 'additionalCoatLayer1',
        layer: 7,
        products: [19],
        selectedProduct: 19,
        changedProduct: false
      }
    ],
    construction: {
      composition: [
        {
          title: { de: 'Systemgruppen' },
          output: 'Effizientes Standardsystem',
          type: 'systemGroup',
          value: 'systemgroup-1'
        },
        {
          title: { de: 'Räume' },
          output: 'Wohnräume',
          type: 'room',
          value: 'room-1'
        },
        {
          title: { de: 'Räume' },
          output: 'Bad',
          type: 'room',
          value: 'room-2'
        },
        {
          title: { de: 'Räume' },
          output: 'Flur',
          type: 'room',
          value: 'room-4'
        },
        {
          title: { de: 'Räume' },
          output: 'Keller',
          type: 'room',
          value: 'room-5'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Schadstoffgeprüft',
          type: 'environmentHealth',
          value: 'environmenthealth-1'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'TÜV',
          type: 'environmentHealth', 
          value: 'environmenthealth-2'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Streiflichtunempfindlich',
          type: 'resilience', 
          value: 'resilience-2'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Matt',
          type: 'designSurface', 
          value: 'designsurface-1'
        },
        {
          title: { de: 'Verarbeitung' },
          output: 'Einfach',
          type: 'processing', 
          value: 'processing-1'
        },
        {
          title: { de: 'Verarbeitung' },
          output: 'Schnell',
          type: 'processing', 
          value: 'processing-4'
        },
        {
          title: { de: 'Verarbeitungszeit' },
          output: 'Stufe 1',
          type: 'processingTime', 
          value: 'processingtime-1'
        },
        {
          title: { de: 'Preisniveau' },
          output: '€',
          type: 'priceLevel', 
          value: 'pricelevel-1'
        }
      ]
    }
  }
}
