<template>
  <header class="col-12 alert alert-secondary text-center">
    <span>{{
      listLength +
      " " +
      countResult +
      " " +
      (listLength > 1 || listLength == 0 ? meetPlural : meetSingular) +
      " " +
      selectionCriteria +
      ". " +
      alternatives
    }}</span>
  </header>
</template>

<script>
import { getString } from "../../language";

export default {
  name: "StoCscResultHead",
  props: {
    listLength: Number,
  },
  data: function () {
    return {
      countResult: getString("result.headline.countResult"),
      meetSingular: getString("result.headline.meet.singular"),
      meetPlural: getString("result.headline.meet.plural"),
      selectionCriteria: getString("result.headline.selectionCriteria"),
      alternatives: getString("result.headline.alternatives"),
    };
  },
};
</script>
