<template>
  <form class="bg-gray p-3" v-on:submit.prevent="sendForm">
    <div
      v-if="formState.success || formState.error"
      :class="['alert', formState.success ? 'alert-success' : 'alert-danger']"
      role="alert"
      v-html="formState.alertMessage"
    />
    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="contactForm-firstname">{{ firstname }} *</label>
        <input
          type="text"
          class="form-control"
          id="contactForm-firstname"
          :value="formData.firstname"
          @focus="focus('firstname')"
          @blur="blur('firstname')"
          @input="setFirstname"
          :placeholder="firstname"
          required
        />
        <!--<div class="valid-feedback">Looks good!</div>-->
        <!--<div class="invalid-feedback">Looks bad!</div>-->
      </div>
      <div class="col-md-6 mb-3">
        <label for="contactForm-lastname">{{ lastname }} *</label>
        <input
          type="text"
          class="form-control"
          id="contactForm-lastname"
          :value="formData.lastname"
          @focus="focus('lastname')"
          @blur="blur('lastname')"
          @input="setLastname"
          :placeholder="lastname"
          required
        />
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="contactForm-company">{{ company }} *</label>
        <input
          type="text"
          class="form-control"
          id="contactForm-company"
          :value="formData.company"
          @focus="focus('company')"
          @blur="blur('company')"
          @input="setCompany"
          :placeholder="company"
          required
        />
      </div>
      <div class="col-md-6 mb-3">
        <label for="contactForm-street">{{ street }}</label>
        <input
          type="text"
          class="form-control"
          id="contactForm-street"
          :value="formData.street"
          @focus="focus('street')"
          @blur="blur('street')"
          @input="setStreet"
          :placeholder="street"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="contactForm-postcode">{{ postcode }}</label>
        <input
          type="text"
          class="form-control"
          id="contactForm-postcode"
          :value="formData.postcode"
          @focus="focus('postcode')"
          @blur="blur('postcode')"
          @input="setPostcode"
          :placeholder="postcode"
        />
      </div>
      <div class="col-md-6 mb-3">
        <label for="contactForm-city">{{ city }}</label>
        <input
          type="text"
          class="form-control"
          id="contactForm-city"
          :value="formData.city"
          @focus="focus('city')"
          @blur="blur('city')"
          @input="setCity"
          :placeholder="city"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="contactForm-phone">{{ phone }}</label>
        <input
          type="text"
          class="form-control"
          id="contactForm-phone"
          :value="formData.phone"
          @focus="focus('phone')"
          @blur="blur('phone')"
          @input="setPhone"
          :placeholder="phone"
        />
      </div>
      <div class="col-md-6 mb-3">
        <label for="contactForm-email">{{ email }} *</label>
        <input
          type="email"
          class="form-control"
          id="contactForm-email"
          :value="formData.email"
          @focus="focus('email')"
          @blur="blur('email')"
          @input="setEmail"
          :placeholder="email"
          required
        />
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12 mb-3">
        <label for="contactForm-text">{{ text }}</label>
        <textarea
          type="text"
          class="form-control"
          id="contactForm-text"
          :value="formData.text"
          @focus="focus('text')"
          @blur="blur('text')"
          @input="setText"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12 mb-3">
        <span
          ><small>{{ required }}</small></span
        >
        <button
          ref="formSubmit"
          class="btn btn-warning float-right"
          type="submit"
          :disabled="formState.sending"
          @click="clickSubmit"
        >
          {{ buttonSend }}
        </button>
      </div>
    </div>
    <StoCscContactSummary />
  </form>
</template>

<script>
import { mapGetters } from "vuex";

import StoCscContactSummary from "./Summary.vue";

import { getString } from "../../language";
import { dataLayerPush, getDataLayerDefault } from "../../helper/tracker";

export default {
  name: "StoCscContactForm",
  data: function () {
    return {
      firstname: getString("contact.input.firstname"),
      lastname: getString("contact.input.lastname"),
      company: getString("contact.input.company"),
      street: getString("contact.input.street"),
      postcode: getString("contact.input.postcode"),
      city: getString("contact.input.city"),
      phone: getString("contact.input.phone"),
      email: getString("contact.input.email"),
      text: getString("contact.input.text"),
      buttonSend: getString("contact.buttonSend"),
      required: getString("contact.required"),
    };
  },
  components: {
    StoCscContactSummary,
  },
  computed: {
    ...mapGetters({
      formData: "contact/formData",
      formState: "contact/formState",
      stateFilter: "filter/stateFilter",
      statsFormData: "stats/formData",
    }),
  },
  methods: {
    focus(name) {
      this.$gtm.trackEvent({
        event: "editForm",
        category: "kontakt",
        action: "focus",
        label: getString("contact.input." + name).toLowerCase(),
        value: null,
      });
      const dataToPush = getDataLayerDefault("field");
      dataLayerPush(dataToPush);
    },

    blur(name) {
      this.$gtm.trackEvent({
        event: "editForm",
        category: "kontakt",
        action: "blur",
        label: getString("contact.input." + name).toLowerCase(),
        value: null,
      });
      const dataToPush = getDataLayerDefault("field");
      dataLayerPush(dataToPush);
    },

    setFirstname(input) {
      this.$store.dispatch("contact/setFormData", {
        name: "firstname",
        value: input.target.value,
      });
    },

    setLastname(input) {
      this.$store.dispatch("contact/setFormData", {
        name: "lastname",
        value: input.target.value,
      });
    },

    setCompany(input) {
      this.$store.dispatch("contact/setFormData", {
        name: "company",
        value: input.target.value,
      });
    },

    setStreet(input) {
      this.$store.dispatch("contact/setFormData", {
        name: "street",
        value: input.target.value,
      });
    },

    setPostcode(input) {
      this.$store.dispatch("contact/setFormData", {
        name: "postcode",
        value: input.target.value,
      });
    },

    setCity(input) {
      this.$store.dispatch("contact/setFormData", {
        name: "city",
        value: input.target.value,
      });
    },

    setPhone(input) {
      this.$store.dispatch("contact/setFormData", {
        name: "phone",
        value: input.target.value,
      });
    },

    setEmail(input) {
      this.$store.dispatch("contact/setFormData", {
        name: "email",
        value: input.target.value,
      });
    },

    setText(input) {
      this.$store.dispatch("contact/setFormData", {
        name: "text",
        value: input.target.value,
      });
    },

    trackEditForm(action, label) {
      this.$gtm.trackEvent({
        event: "editForm",
        category: "kontakt",
        action: action,
        label: label,
        value: null,
      });
    },

    clickSubmit(event) {
      let invalid = false;
      for (let i = 0; i < this._vnode.elm.length; i++) {
        const el = this._vnode.elm[i];
        if (
          el.type === "textarea" ||
          el.type === "text" ||
          el.type === "email"
        ) {
          const name = el.id.split("-")[1];
          const action = getString("contact.input." + name).toLowerCase();
          if (el.required) {
            if (!el.validity.valid) invalid = true;
            this.trackEditForm(
              action,
              el.validity.valid ? "valid" : "not_valid"
            );
          } else {
            this.trackEditForm(
              action,
              el.value.length > 0 ? "filled" : "not_filled"
            );
          }
        }
      }
      if (invalid) this.trackEditForm("absenden", "not_success");
    },

    sendForm(e) {
      this.trackEditForm("absenden", "success");
      this.$store.dispatch("contact/sendForm", {
        stateFilter: this.stateFilter,
        projectName: this.statsFormData.name,
        size: this.statsFormData.size,
        shade: this.statsFormData.shade,
      });
    },
  },
};
</script>
