import 'babel-polyfill'

import './assets/scss/app.scss'
 
import './helper/popover.js'

import Vue from 'vue'
import App from './components/App.vue'

import VueCookies from 'vue-cookies'
import VueGtm from 'vue-gtm'

import store from './store'

(function (w) {
  w.URLSearchParams = w.URLSearchParams || function (searchString) {
    const self = this
    self.searchString = searchString
    self.get = function (name) {
      var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(self.searchString)
      if (results == null) {
        return null
      } else {
        return decodeURI(results[1]) || 0
      }
    }
  }
})(window)
Vue.config.devtools = true
Vue.use(VueCookies)

Vue.use(VueGtm, {
  id: 'GTM-W4VLMGXD', // or ['GTM-xxxxxxx', 'GTM-xxxxxxx'], // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x'
  // },
  loadScript: true,
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: process.env.NODE_ENV !== 'production' // Whether or not display console logs debugs (optional)
})

const appContainer = document.querySelector('[data-id="app"]')

// add a window apiPrefix if on localhost
if (window.location.hostname === 'localhost') {
  window.apiPrefix = 'http://localhost:9090'
} else {
  window.apiPrefix = ''
}

new Vue({
  store,
  render: h => h(App)
}).$mount(appContainer)
