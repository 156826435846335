import { getCompositionParts } from "./values";

const compositionSectionCompareEqual = (type, item, value) => {
  if (value === null) {
    return true;
  }
  const search = value.split("-");
  const parts = getCompositionParts(item, type);
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].value === value) return true; // direct match
    const subject = String(parts[i].value).split("-");
    if (
      subject.length === 2 &&
      search[0] === subject[0] &&
      Number(search[1]) === Number(subject[1])
    )
      return true;
  }
  return false;
};

export default {
  systemGroup: (item, value) => compositionSectionCompareEqual("systemGroup", item, value),
  room: (item, value) => compositionSectionCompareEqual("room", item, value),
  environmentHealth: (item, value) => compositionSectionCompareEqual("environmentHealth", item, value),
  resilience: (item, value) => compositionSectionCompareEqual("resilience", item, value),
  designSurface: (item, value) => compositionSectionCompareEqual("designSurface", item, value),
  processing: (item, value) => compositionSectionCompareEqual("processing", item, value),
  processingTime: (item, value) => compositionSectionCompareEqual("processingTime", item, value),
  priceLevel: (item, value) => compositionSectionCompareEqual("priceLevel", item, value)
};
