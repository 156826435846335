export default {
  image: 's1.jpg',
  productData: {
    name: 'Effizientes Standardsystem S1',
    shorthand: "S1",
    advantages: [
      "Schnelle Verarbeitung",
      "Einfache Verarbeitung",
      "Kostengünstige Produkte",
      "Zeitsparend"
    ],
    technical: [
      "Strukturierte Flächen",
      "Glanzgrad stumpfmatt",
      "TÜV"
    ],
    systemStructure: [
      {
        type: 'subsurface',
        layer: 1,
        products: [0],
        selectedProduct: 0,
        changedProduct: false
      },
      {
        type: 'joints',
        layer: 2,
        products: [1],
        selectedProduct: 1,
        changedProduct: false
      },
      {
        type: 'firstCoat',
        layer: 3,
        products: [3],
        selectedProduct: 3,
        changedProduct: false
      },
      {
        type: 'glue',
        layer: 4,
        products: [4],
        selectedProduct: 4,
        changedProduct: false
      },
      {
        type: 'wallCovering',
        layer: 5,
        products: [5, 6],
        selectedProduct: 5,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer1',
        layer: 6,
        products: [7, 22],
        selectedProduct: 7,
        changedProduct: false
      },
      {
        type: 'finalCoatLayer2',
        layer: 6,
        products: [7, 22],
        selectedProduct: 7,
        changedProduct: false
      }
    ],
    construction: {
      composition: [
        {
          title: { de: 'Systemgruppen' },
          output: 'Effizientes Standardsystem',
          type: 'systemGroup',
          value: 'systemgroup-1'
        },
        {
          title: { de: 'Räume' },
          output: 'Wohnräume',
          type: 'room',
          value: 'room-1'
        },
        {
          title: { de: 'Räume' },
          output: 'Keller',
          type: 'room',
          value: 'room-5'
        },
        {
          title: { de: 'Räume' },
          output: 'Garagen',
          type: 'room',
          value: 'room-6'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'Schadstoffgeprüft',
          type: 'environmentHealth',
          value: 'environmenthealth-1'
        },
        {
          title: { de: 'Umwelt- und Gesundheitsaspekte' },
          output: 'TÜV',
          type: 'environmentHealth', 
          value: 'environmenthealth-2'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Streiflichtunempfindlich',
          type: 'resilience', 
          value: 'resilience-2'
        },
        {
          title: { de: 'Belastbarkeit' },
          output: 'Risssicher',
          type: 'resilience', 
          value: 'resilience-6'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Matt',
          type: 'designSurface', 
          value: 'designsurface-1'
        },
        {
          title: { de: 'Design / Oberfläche' },
          output: 'Strukturiert',
          type: 'designSurface', 
          value: 'designsurface-7'
        },
        {
          title: { de: 'Verarbeitung' },
          output: 'Einfach',
          type: 'processing', 
          value: 'processing-1'
        },
        {
          title: { de: 'Verarbeitung' },
          output: 'Rückbaufähig',
          type: 'processing', 
          value: 'processing-2'
        },
        {
          title: { de: 'Verarbeitung' },
          output: 'Schnell',
          type: 'processing', 
          value: 'processing-4'
        },
        {
          title: { de: 'Verarbeitungszeit' },
          output: 'Stufe 1',
          type: 'processingTime', 
          value: 'processingtime-1'
        },
        {
          title: { de: 'Preisniveau' },
          output: '€',
          type: 'priceLevel', 
          value: 'pricelevel-1'
        }
      ]
    }
  }
}
