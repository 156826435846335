<template>
  <div class="container-fluid px-0">
    <div class="form bg-white pl-0 row my-4 py-2">
      <div class="col-md-6 col-lg-4">
        <div class="row align-items-center mt-3">
          <label
            for="statsForm-projectName"
            class="col-5 col-sm-4 col-md-5 col-lg-5 col-xl-6 mb-0 pb-0 font-weight-bold"
            >{{ projectName }}</label
          >
          <input
            type="text"
            class="form-control col-6 col-xl-5"
            id="statsForm-projectName"
            :value="formData.name"
            @focus="focus('projectName')"
            @blur="blur('projectName')"
            @input="setprojectName"
            required
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="row align-items-center mt-3">
          <label
            for="statsForm-projectSize"
            class="col-5 col-sm-4 col-md-5 col-lg-6 col-xl-5 mb-0 pb-0 font-weight-bold"
            v-html="projectSize"
          />
          <input
            class="form-control col-3"
            id="statsForm-projectSize"
            :value="formData.size"
            @focus="focus('projectSize')"
            @blur="blur('projectSize')"
            @input="setprojectSize"
            type="number"
            pattern="[0-9]+([\.,][0-9]+)?"
            step="0.01"
            required
          />
          <span class="pl-2" v-html="projectSizeSuffix" />
        </div>
      </div>
      <div class="col-md-6 col-lg-4 switch-toggle-wrapper">
        <div
          class="row align-items-center mt-3"
          style="flex-wrap: nowrap !important; width: 100%"
        >
          <label
            for="statsForm-projectSize"
            class="col-5 col-sm-4 col-md-5 col-lg-4 col-xl-3 mb-0 pb-0 font-weight-bold"
            v-html="projectShade"
          />
          <fieldset>
            <div class="switch-toggle alert alert-light">
              <input
                id="shade_white"
                type="radio"
                :checked="formData.shade === 'white' ? true : false"
                @change="setShade('white')"
              />
              <label
                :style="formData.shade === 'white' ? 'color: #000' : ''"
                for="shade_white"
                onclick=""
                v-html="shadeWhite"
              />

              <input
                id="shade_shaded"
                type="radio"
                :checked="formData.shade === 'shaded' ? true : false"
                @change="setShade('shaded')"
              />
              <label
                :style="formData.shade === 'shaded' ? 'color: #000' : ''"
                for="shade_shaded"
                onclick=""
                v-html="shadeShaded"
              />

              <a class="btn btn-primary"></a>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getString } from "../../language";

export default {
  name: "StoCscProjectStats",
  data: function () {
    return {
      headline: getString("projectStats.headline"),
      projectSize: getString("projectStats.size"),
      projectSizeSuffix: getString("projectStats.sizeSuffix"),
      projectName: getString("projectStats.name"),
      projectShade: getString("projectStats.shade"),
      shadeWhite: getString("projectStats.shadeWhite"),
      shadeShaded: getString("projectStats.shadeShaded"),
    };
  },
  computed: {
    ...mapGetters({
      formData: "stats/formData",
      formState: "stats/formState",
    }),
  },
  methods: {
    focus(name) {
      this.$gtm.trackEvent({
        event: "editForm",
        category: "statsForm-" + name,
        action: "focus",
        label: getString("stats.input." + name).toLowerCase(),
        value:
          name === "projectSize"
            ? document.getElementById("statsForm-" + name).value +
              " " +
              this.projectSizeSuffix
            : null,
      });
    },
    blur(name) {
      this.$gtm.trackEvent({
        event: "editForm",
        category: "statsForm-" + name,
        action: "blur",
        label: getString("stats.input." + name).toLowerCase(),
        value:
          name === "projectSize"
            ? document.getElementById("statsForm-" + name).value +
              " " +
              this.projectSizeSuffix
            : null,
      });
    },
    resetFilter: function (value) {
      this.$gtm.trackEvent({
        event: "clickElement",
        category: "eigenschaften",
        action: "button",
        label: "Eigenschaften zurücksetzen [X]",
        value: null,
      });

      this.$store.dispatch("filter/resetFilter");
      this.$store.dispatch("contact/hideForm");
    },
    setprojectName(input) {
      this.$store.dispatch("stats/setFormData", {
        name: "name",
        value: input.target.value,
      });
    },
    setprojectSize(input) {
      this.$store.dispatch("stats/setFormData", {
        name: "size",
        value: parseFloat(input.target.value),
      });
    },
    setShade(input) {
      this.$store.dispatch("stats/setFormData", {
        name: "shade",
        value: input,
      });
    },
  },
};
</script>
<style scoped>
label {
  line-height: 19px;
}
</style>