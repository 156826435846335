<template>
  <div :class="['row filter-row mx-0', addContainerClasses]">
    <div class="col-12 col-md-4 col-xl-3 pb-3 font-weight-bold">
      <Popover info="designSurface" :title="title" />
    </div>
    <div class="col-12 col-md-8 col-xl-9">
      <template v-for="(item, index) in items">
        <button
          type="button"
          :key="index"
          :data-trigger="item.value"
          :class="[
            'btn btn-sm btn-outline-filter',
            filterUiState('designSurface', item.value),
          ]"
          @mouseover="item.info ? showPopover(item.value) : null"
          @mouseleave="item.info ? hidePopover(item.value) : null"
          @click="selectValue(item.value)"
        >
          <template v-if="item.info">
            <PopoverSelector
              :infotitle="item.value"
              :infotext="item.info"
              :title="item.title"
              @hide="hidePopover(item.value)"
            />
          </template>
          <template v-else>
            {{ item.title }}
          </template>
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { trackCallstack } from "../../../helper/tracker";
import Popover from "../../Popover.vue";
import PopoverSelector from "../../PopoverSelector.vue";

export default {
  name: "StoCscFilterSectionDesignSurface",
  components: {
    Popover,
    PopoverSelector,
  },
  props: {
    addContainerClasses: String,
  },
  data: function () {
    return {
      title: "Design / Oberfläche",
      items: [
        { title: "Matt", value: "designsurface-1" },
        { title: "Satinmatt", value: "designsurface-2" },
        { title: "Satin", value: "designsurface-3" },
        { title: "Volle Farbtonauswahl", value: "designsurface-5" },
        { title: "Glatt", value: "designsurface-6" },
        { title: "Strukturiert", value: "designsurface-7" },
        { title: "Erhöhte optische Ansprüche", value: "designsurface-8" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      filterUiState: "filter/uiState",
      filterState: "filter/state",
    }),
  },
  methods: {
    showPopover(infotitle) {
      const popover = document.querySelector(`[data-popover="${infotitle}"]`);
      const trigger = document.querySelector(`[data-trigger="${infotitle}"]`);
      const container = trigger.closest(".col-12.col-md-8.col-xl-9");
      if (popover && trigger && container) {
        const triggerRect = trigger.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        popover.style.top = `${triggerRect.bottom - containerRect.top}px`;
        popover.classList.remove("d-none");

        const arrow = popover.querySelector(".arrow");
        if (arrow) {
          arrow.style.left = `${
            triggerRect.left +
            triggerRect.width / 2 -
            containerRect.left -
            arrow.offsetWidth / 2
          }px`;
        }
      }
    },
    hidePopover(infotitle) {
      const popover = document.querySelector(`[data-popover="${infotitle}"]`);
      if (popover) {
        popover.classList.add("d-none");
      }
    },
    selectValue: function (value) {
      let title = "";
      const result = this.items.filter((item) => item.value === value);
      if (result.length > 0) title = result[0].title;

      const uiState = this.filterUiState("designSurface", value);

      this.$gtm.trackEvent({
        event: "filter",
        category: this.title,
        action:
          uiState === "pending"
            ? "hinzufügen"
            : uiState === "active"
            ? "reaktivieren"
            : "aktivieren",
        label: title,
        value: value,
      });

      this.$store
        .dispatch("filter/setValue", {
          name: "designSurface",
          title: this.title + " " + title,
          value: value,
        })
        .then(() => trackCallstack(this.$gtm, this.filterState._callstack));
    },
  },
};
</script>
