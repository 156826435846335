
export default {

  displayForm: state => state.displayForm,

  formData: state => state.formData,

  formState: state => {
    return {
      name: state.formData.name,
      size: state.formData.size,
      shade: state.formData.shade,
    }
  }
}
