<template>
  <aside class="col-12 col-md-5 py-4">
    <img class="w-100" v-bind:src="'/assets/images/systems/' + image" />
  </aside>
</template>

<script>
export default {
  name: "StoCscResultItemAside",
  props: {
    image: String,
  },
};
</script>
