import { getString } from '@/language'

export const getCompositionParts = (item, type) => {
  return item &&
    item.productData &&
    item.productData.construction &&
    Array.isArray(item.productData.construction.composition)
    ? item.productData.construction.composition.filter(
      (part) => part.type === type
    )
    : []
}

const getCompositionPartValues = (item, type) => {
  const values = []

  const parts = getCompositionParts(item, type)
  parts.map((part) => values.push(part.value))

  return values
}

const availables = {
  systemGroup: [
    'systemgroup-1',
    'systemgroup-2',
    'systemgroup-3',
    'systemgroup-4',
    null
  ],
  room: [
    'room-1',
    'room-2',
    'room-3',
    'room-4',
    'room-5',
    'room-6',
    null
  ],
  environmentHealth: [
    'environmenthealth-1',
    'environmenthealth-2',
    'environmenthealth-3',
    'environmenthealth-4',
    'environmenthealth-5',
    'environmenthealth-6',
    'environmenthealth-7',
    'environmenthealth-8',
    null
  ],
  resilience: [
    'resilience-1',
    'resilience-2',
    'resilience-3',
    'resilience-4',
    'resilience-5',
    'resilience-6',
    'resilience-7',
    null
  ],
  designSurface: [
    'designsurface-1',
    'designsurface-2',
    'designsurface-3',
    'designsurface-5',
    'designsurface-6',
    'designsurface-7',
    'designsurface-8',
    null
  ],
  processing: [
    'processing-1',
    'processing-2',
    'processing-3',
    'processing-4',
    null,
  ],
  processingTime: [
    'processingtime-1',
    'processingtime-2',
    'processingtime-3',
    null,
  ],
  priceLevel: [
    'pricelevel-1',
    'pricelevel-2',
    'pricelevel-3',
    null,
  ]
}

export default availables

export const objMap = {
  systemGroup: (item) => {
    const values = getCompositionPartValues(item, 'systemGroup')
    return values.length > 0 ? values : [null]
  },
  room: (item) => {
    const values = getCompositionPartValues(item, 'room')
    return values.length > 0 ? values : [null]
  },
  environmentHealth: (item) => {
    const values = getCompositionPartValues(item, 'environmentHealth')
    return values.length > 0 ? values : [null]
  },
  resilience: (item) => {
    const values = getCompositionPartValues(item, 'resilience')
    return values.length > 0 ? values : [null]
  },
  designSurface: (item) => {
    const values = getCompositionPartValues(item, 'designSurface')
    return values.length > 0 ? values : [null]
  },
  processing: (item) => {
    const values = getCompositionPartValues(item, 'processing')
    return values.length > 0 ? values : [null]
  },
  processingTime: (item) => {
    const values = getCompositionPartValues(item, 'processingTime')
    return values.length > 0 ? values : [null]
  },
  priceLevel: (item) => {
    const values = getCompositionPartValues(item, 'priceLevel')
    return values.length > 0 ? values : [null]
  }
}

export const validate = (name, value) => {
  let result = []

  const values = availables[name] ? availables[name] : []
  if (Array.isArray(value)) {
    for (let i = 0; i < value.length; i++) {
      if (values.indexOf(value[i]) >= 0) result.push(value[i])
    }
  } else if (values.indexOf(value) >= 0) result = [value]

  return result
}

export const labels = {
  1: {
    title: "Umweltlabel Frankreich",
    image: "umweltlabel_frankreich.svg"
  },
  2: {
    title: "Label Schadstoffgeprüft",
    image: "label_schadstoffgeprueft.svg"
  },
  3: {
    title: "TÜV SÜD",
    image: "tuev_sued.svg"
  },
  4: {
    title: "Umweltetikett CH",
    image: "umweltetikett_schweiz.svg"
  },
  5: {
    title: "Label Konservierungsmittelfrei",
    image: "umweltlabel_konservierungsmittelfrei.svg"
  },
  6: {
    title: "TÜV NORD",
    image: "tuev_nord.svg"
  },
  7: {
    title: "OEKO-Tex",
    image: "oeko_tex.svg"
  },
  8: {
    title: "FSC Mix",
    image: "fsc_mix.svg"
  },
  9: {
    title: "Natureplus",
    image: "nature_plus.svg"
  },
  10: {
    title: "Österreichisches Umweltzeichen",
    image: "oesterreichisches_umweltzeichen.svg"
  },
  11: {
    title: "(Sto) IQ",
    image: "sto_iq.svg"
  },
  12: {
    title: "Label ohne bioziden Filmschutz",
    image: "umweltlabel_ohne_bioziden_filmschutz.svg"
  }
}

export const products = {
  0: {
    title: "GKP/GFP",
    structureType: "subsurface"
  },
  1: {
    title: "StoLevell In Fill",
    structureType: "joints",
    consumption: 0.5,
    unit: "kg",
    fineness: "",
    containerType: "Sack",
    sizes: [15, 5],
    labels: [1],
    itemNumbers: ["02970-001", "02970-002"]
  },
  2: {
    title: "StoLevell In Fill",
    structureType: "surfaceFilling",
    consumption: 1,
    unit: "kg",
    fineness: "",
    containerType: "Sack",
    sizes: [15, 5],
    labels: [1],
    itemNumbers: ["02970-001", "02970-002"]
  },
  3: {
    title: "StoPrim Plex",
    structureType: "firstCoat",
    consumption: 0.2,
    unit: "l",
    fineness: "",
    containerType: "Kanister",
    sizes: [20, 10, 5],
    labels: [1, 2, 3, 4],
    itemNumbers: ["00518-002", "00518-001", "00518-009"]
  },
  4: {
    title: "StoColl TG",
    structureType: "glue",
    consumption: 0.15,
    unit: "kg",
    fineness: "",
    containerType: "Eimer",
    sizes: [16],
    labels: [5],
    itemNumbers: ["00259-001"]
  },
  5: {
    title: "StoEuro Trend",
    structureType: "wallCovering",
    consumption: 1,
    unit: "m²",
    fineness: "mittel",
    containerType: "Rolle",
    specialUnits: ["1 x 0,75 m x 125 m", "6 x 0,53 x 33,5m"],
    sizes: [93.75, 106.53],
    labels: [6],
    itemNumbers: ["03489-001", "03490-001"]
  },
  6: {
    title: "StoEuro Trend",
    structureType: "wallCovering",
    consumption: 1,
    unit: "m²",
    fineness: "grob",
    containerType: "Rolle",
    specialUnits: ["1 x 0,75 m x 125 m", "6 x 0,53 x 33,5m"],
    sizes: [93.75, 106.53],
    labels: [6],
    itemNumbers: ["03487-001", "03488-001"]
  },
  7: {
    title: "StoColor Basic",
    structureType: "finalCoat",
    consumption: 0.15,
    unit: "l",
    fineness: "",
    containerType: "Eimer",
    sizes: [15, 10],
    labels: [1, 3, 4, 5],
    itemNumbers: [["00039-001", "00039-006"], ["00039-001", "00039-006"]]
  },
  8: {
    title: "StoLevell In AS",
    structureType: "surfaceFilling",
    consumption: 1.70,
    unit: "kg",
    fineness: "",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 2, 3],
    itemNumbers: ["01245-003"]
  },
  9: {
    title: "StoLevell In AS",
    structureType: "surfaceFilling",
    consumption: 1.70,
    unit: "kg",
    fineness: "",
    containerType: "Sack (PE)",
    sizes: [25],
    labels: [1, 2, 3],
    itemNumbers: ["01245-001"]
  },
  10: {
    title: "StoPrim GT",
    structureType: "firstCoat",
    consumption: 0.20,
    unit: "l",
    fineness: "",
    containerType: "Eimer",
    sizes: [15],
    labels: [1, 3, 4],
    itemNumbers: ["09609-001"]
  },
  11: {
    title: "StoTap Pro 100 A",
    structureType: "wallCovering",
    consumption: 1,
    unit: "m²",
    fineness: "",
    containerType: "Rolle",
    specialUnits: ["1 x 1,00 m x 40m", "1 x 1,00 m x 25 m"],
    sizes: [40, 25],
    labels: [1, 2, 7],
    itemNumbers: ["09337-002", "09337-001"]
  },
  12: {
    title: "StoColor Rapid Ultramatt",
    structureType: "finalCoat",
    consumption: 0.14,
    unit: "l",
    fineness: "",
    containerType: "Eimer",
    sizes: [15, 10, 5, 2.5],
    labels: [1, 2, 3],
    itemNumbers: [["08109-004", "08109-008"], ["08109-003", "08109-007"], ["08109-002", "08109-006"], ["08109-001", "08109-005"]]
  },
  /*13: {
    title: "StoPrim Plex",
    structureType: "firstCoat",
    consumption: 0.20,
    unit: "l",
    fineness: "",
    containerType: "Kanister",
    sizes: [20, 10, 5],
    labels: [1, 2, 3, 4]
  },*/
  14: {
    title: "StoLook Decor",
    structureType: "finalCoat",
    consumption: 0.70,
    unit: "kg",
    fineness: "fein 1,0",
    containerType: "Eimer",
    sizes: [21],
    labels: [1, 3],
    itemNumbers: [["00300-001", "00300-007"]]
  },
  15: {
    title: "StoLook Decor",
    structureType: "finalCoat",
    consumption: 0.80,
    unit: "kg",
    fineness: "medium 1,5",
    containerType: "Eimer",
    sizes: [21],
    labels: [1, 3],
    itemNumbers: [["00302-001", "00302-005"]]
  },
  16: {
    title: "StoColor Select Matt",
    structureType: "additionalCoat",
    consumption: 0.14,
    unit: "l",
    fineness: "",
    containerType: "Eimer",
    sizes: [15],
    labels: [2, 3],
    itemNumbers: ["00284-001"]
  },
  17: {
    title: "StoLook Struktur",
    structureType: "finalCoat",
    consumption: 0.70,
    unit: "kg",
    fineness: "F",
    containerType: "Eimer",
    sizes: [20, 10],
    labels: [1, 2, 3, 4],
    itemNumbers: [["00282-001", "00282-002"], ["00282-005", "00282-006"]]
  },
  18: {
    title: "StoLook Struktur",
    structureType: "finalCoat",
    consumption: 0.90,
    unit: "kg",
    fineness: "G",
    containerType: "Eimer",
    sizes: [20],
    labels: [1, 2, 3, 4],
    itemNumbers: [["00571-001", "00571-002"]]
  },
  19: {
    title: "StoSil Patina",
    structureType: "additionalCoat",
    consumption: 0.06,
    unit: "l",
    fineness: "",
    containerType: "Eimer",
    sizes: [15, 2.5],
    labels: [1, 2, 3, 4],
    itemNumbers: ["04970-003", "04970-001"]
  },
  20: {
    title: "StoEuro Vlies",
    structureType: "wallCovering",
    consumption: 1,
    unit: "m²",
    fineness: "mittel",
    containerType: "Rolle",
    specialUnits: ["1 x 0,75 m x 125 m", "2 x 0,75 x 25 m", "2 x 1,06 x 25 m"],
    sizes: [93.75, 37.5, 53],
    labels: [],
    itemNumbers: ["09625-003", "09625-001", "09625-002"]
  },
  21: {
    title: "StoEuro Vlies",
    structureType: "wallCovering",
    consumption: 1,
    unit: "m²",
    fineness: "grob",
    containerType: "Rolle",
    specialUnits: ["1 x 0,75 m x 125 m", "2 x 0,75 x 25 m", "2 x 1,06 x 25 m"],
    sizes: [93.75, 37.5, 53],
    labels: [],
    itemNumbers: ["09626-003", "09626-001", "09626-002"]
  },
  22: {
    title: "StoColor Rapid Neo",
    structureType: "finalCoat",
    consumption: 0.14,
    unit: "l",
    fineness: "",
    containerType: "Eimer",
    sizes: [15],
    labels: [1, 3, 4, 5],
    itemNumbers: [["02260-002", "02260-001"]]
  },
  23: {
    title: "StoColl Tap",
    structureType: "glue",
    consumption: 0.16,
    unit: "kg",
    fineness: "",
    containerType: "Eimer",
    sizes: [16],
    labels: [1, 2, 3],
    itemNumbers: ["00828-043"]
  },
  24: {
    title: "StoTap Evo",
    structureType: "wallCovering",
    consumption: 1,
    unit: "m²",
    fineness: "110",
    containerType: "Rolle",
    specialUnits: ["1 x 1,00 x 50,00 m", "1 x 0,75 m x 1,00 m", "2 x 0,75 m x 50 m"],
    sizes: [50, 75, 75],
    labels: [2, 3, 8],
    itemNumbers: ["00541-009", "00541-008", "00541-007"]
  },
  25: {
    title: "StoTap Evo",
    structureType: "wallCovering",
    consumption: 1,
    unit: "m²",
    fineness: "130",
    containerType: "Rolle",
    specialUnits: ["2 x 1,00 x 50,00 m", "1 x 0,75 m x 1,00 m", "2 x 0,75 m x 50 m"],
    sizes: [100, 75, 75],
    labels: [2, 3, 8],
    itemNumbers: ["00541-003", "00541-002", "00541-001"]
  },
  26: {
    title: "StoTap Evo",
    structureType: "wallCovering",
    consumption: 1,
    unit: "m²",
    fineness: "150",
    containerType: "Rolle",
    specialUnits: ["2 x 1,00 x 50,00 m", "1 x 0,75 m x 1,00 m", "2 x 0,75 m x 50 m"],
    sizes: [100, 75, 75],
    labels: [2, 3, 8],
    itemNumbers: ["00541-006", "00541-005", "00541-004"]
  },
  27: {
    title: "StoColor Sil Comfort",
    structureType: "finalCoat",
    consumption: 0.13,
    unit: "l",
    fineness: "",
    containerType: "Eimer",
    sizes: [15, 10, 5],
    labels: [1, 3, 4, 5, 9, 10],
    itemNumbers: [["09547-004", "09547-003"], ["09547-021", "09547-020"], ["09547-019", "09547-018"]]
  },
  28: {
    title: "StoPrep Sil",
    structureType: "intermediateCoat",
    consumption: 0.35,
    unit: "kg",
    fineness: "",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: ["04968-001"]
  },
  29: {
    title: "StoDecosit",
    structureType: "finalCoat",
    consumption: 2,
    unit: "kg",
    fineness: "K 1,0",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: [["09620-002", "09620-001"]]
  },
  30: {
    title: "StoDecosit",
    structureType: "finalCoat",
    consumption: 2.20,
    unit: "kg",
    fineness: "K 1,5",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: [["09620-004", "09620-003"]]
  },
  31: {
    title: "StoDecosit",
    structureType: "finalCoat",
    consumption: 2.90,
    unit: "kg",
    fineness: "K 2,0",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: [["09620-006", "09620-005"]]
  },
  32: {
    title: "StoDecosit",
    structureType: "finalCoat",
    consumption: 3.90,
    unit: "kg",
    fineness: "K 3,0",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: [["09620-042", "09620-041"]]
  },
  33: {
    title: "StoDecosit",
    structureType: "finalCoat",
    consumption: 1.70,
    unit: "kg",
    fineness: "R 1,0",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: [["09620-008", "09620-007"]]
  },
  34: {
    title: "StoDecosit",
    structureType: "finalCoat",
    consumption: 2.20,
    unit: "kg",
    fineness: "R 1,5",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: [["09620-010", "09620-009"]]
  },
  35: {
    title: "StoDecosit",
    structureType: "finalCoat",
    consumption: 2.75,
    unit: "kg",
    fineness: "MP 0,5",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: [["09620-012", "09620-011"]]
  },
  36: {
    title: "StoDecosit",
    structureType: "finalCoat",
    consumption: 0.65,
    unit: "kg",
    fineness: "SP 1,0",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: [["09620-014", "09620-013"]]
  },
  37: {
    title: "StoDecosit",
    structureType: "finalCoat",
    consumption: 0.80,
    unit: "kg",
    fineness: "SP 1,5",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: [["09620-016", "09620-015"]]
  },
  38: {
    title: "StoLevell Calce FS",
    structureType: "finalCoat",
    consumption: 1.10,
    unit: "kg",
    fineness: "",
    containerType: "Sack",
    sizes: [25],
    labels: [5, 9],
    itemNumbers: [["09320-008", "09320-007"]]
  },
  39: {
    title: "StoColor Opticryl Satinmatt",
    structureType: "finalCoat",
    consumption: 0.14,
    unit: "l",
    fineness: "",
    containerType: "Eimer",
    sizes: [15, 10, 5, 2.5],
    labels: [1, 2, 3, 4],
    itemNumbers: [["00026-001", "00026-002"], ["00026-005", "00026-008"], ["00026-004", "00026-007"], ["00026-003", "00026-006"]]
  },
  40: {
    title: "StoColl Tex",
    structureType: "glue",
    consumption: 0.20,
    unit: "kg",
    fineness: "",
    containerType: "Eimer",
    sizes: [16],
    labels: [1, 5],
    itemNumbers: ["00828-064"]
  },
  41: {
    title: "StoTex Classic 210 P fein",
    structureType: "wallCovering",
    consumption: 1,
    unit: "m²",
    fineness: "",
    containerType: "Rolle",
    specialUnits: ["1 x 1,00 m x 50 m"],
    sizes: [50],
    labels: [1, 2, 7],
    itemNumbers: ["02549-001"]
  },
  42: {
    title: "StoTex Classic 245 S (Feinjute)",
    structureType: "wallCovering",
    consumption: 1,
    unit: "m²",
    fineness: "",
    containerType: "Rolle",
    specialUnits: ["1 x 1,00 m x 50 m"],
    sizes: [50],
    labels: [1, 2, 7],
    itemNumbers: ["02501-001"]
  },
  43: {
    title: "StoColor Dryonic® M",
    structureType: "finalCoat",
    consumption: 0.05,
    unit: "l",
    fineness: "",
    containerType: "Eimer",
    sizes: [15, 5],
    labels: [1, 4, 11, 12],
    itemNumbers: ["09548-062", "09548-067"]
  },
  44: {
    title: "StoColor Supermatt",
    structureType: "finalCoat",
    consumption: 0.10,
    unit: "l",
    fineness: "",
    containerType: "Eimer",
    sizes: [15, 5],
    labels: [1, 2, 3],
    itemNumbers: [["09378-002", "09378-001"], ["09378-004", "09378-003"]]
  },
  45: {
    title: "StoColor Titanium ASE",
    structureType: "finalCoat",
    consumption: 0.15,
    unit: "l",
    fineness: "",
    containerType: "Eimer",
    sizes: [15, 5, 2.5],
    labels: [1, 2, 3],
    itemNumbers: ["00097-020", "00097-021", "00097-024"]
  },
  46: {
    title: "StoPrep In",
    structureType: "intermediateCoat",
    consumption: 0.25,
    unit: "kg",
    fineness: "",
    containerType: "Eimer",
    sizes: [25, 8],
    labels: [1, 2, 3],
    itemNumbers: ["00872-001", "00872-004"]
  },
  47: {
    title: "StoCalce Fondo",
    structureType: "finalCoat",
    consumption: 0.90,
    unit: "kg",
    fineness: "",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 2, 3],
    itemNumbers: [["01326-009", "01326-008"]]
  },
  48: {
    title: "StoCalce Marmorino",
    structureType: "additionalCoat",
    consumption: 0.20,
    unit: "kg",
    fineness: "",
    containerType: "Eimer",
    sizes: [25, 5],
    labels: [1, 2, 3],
    itemNumbers: [["01321-024", "01321-023"], ["01321-022", "01321-021"]]
  },
  49: {
    title: "StoLevell In Sil",
    structureType: "finalCoat",
    consumption: 1.70,
    unit: "kg",
    fineness: "",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: ["00819-001"]
  },
  50: {
    title: "StoLevell In Sil",
    structureType: "finalCoat",
    consumption: 1.70,
    unit: "kg",
    fineness: "",
    containerType: "Sack (PE)",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: ["00819-002"]
  },
  51: {
    title: "StoLevell In Sil",
    structureType: "surfaceFilling",
    consumption: 1.90,
    unit: "kg",
    fineness: "",
    containerType: "Eimer",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: ["00819-001"]
  },
  52: {
    title: "StoLevell In Sil",
    structureType: "surfaceFilling",
    consumption: 1.90,
    unit: "kg",
    fineness: "",
    containerType: "Sack (PE)",
    sizes: [25],
    labels: [1, 3, 5, 9],
    itemNumbers: ["00819-002"]
  }
}