
import axios from 'axios'

import { getString } from '../../language'

export default {

  resetStats (context) {
    context.commit('resetValues');
  },

  displayForm: context => {
    context.commit('displayForm')
    setTimeout(() => document.querySelector('[data-target="stats-form"]').scrollIntoView({
      behavior: 'smooth'
    }), 50) // delay to ensure mounted form without observing component mount
    return true
  },

  hideForm: context => context.commit('hideForm'),

  setFormData (context, payload) {
    context.commit('setFormData', payload)
  },

  sendForm (context) {
    const filterState = context.rootGetters['filter/state']
    const resultItems = context.rootGetters['results/items']
    const formData = context.rootGetters['stats/formData']

    context.commit('setFormSendState', true)

    axios.post(window.apiPrefix + '/api/', {
      formData: formData,
      resultItems: resultItems,
      filterState: filterState
    }, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then(resp => {
        console.log(resp.data)
        if (resp.data.success) context.commit('setFormSuccess', { message: getString('stats.alert.success') })
        else context.commit('setFormError', { message: getString('stats.alert.error') })
      })
      .catch(err => {
        console.log(err)
        context.commit('setFormError', { message: getString('stats.alert.error') })
      })
      .finally(() => context.commit('setFormSendState', false))
  }

}
