<template>
  <div>
    <span class="popover-trigger">{{ title }}</span>
    <div
      :data-popover="infotitle"
      class="popover fade bs-popover-bottom d-none"
      role="tooltip"
      x-placement="bottom"
    >
      <div class="arrow"></div>
      <h3 class="popover-header"></h3>
      <div class="popover-body">
        <p v-html="infotext" />
        <div
          data-popover-close="system"
          class="d-block d-md-none font-weight-bold text-center align-middle popover-close"
          @click.stop="hide()"
        >
          X
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopoverSelector",
  props: {
    infotitle: String,
    infotext: String,
    title: String,
  },

  methods: {
    show() {
      this.$emit("show", this.infotitle);
    },
    hide() {
      this.$emit("hide", this.infotitle);
    },
  },
};
</script>
<style scoped>
.popover {
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 0.5rem;
}

.popover .arrow {
  position: absolute;
  top: -9px;
  left: 50%;
  width: 1rem;
  height: 0.5rem;
  transform: translateX(-50%);
}

@media only screen and (max-width: 600px) {
  .popover {
    width: 80vw;
  }
}
</style>

