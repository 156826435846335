<template>
  <div class="col-12 pb-2 pb-md-5 filter-container">
    <StoCscFilterHeader />
    <div class="filter-wrapper container-fluid px-0">
      <StoCscFilterSectionSystemGroup addContainerClasses="pt-3 system-group" />
      <StoCscFilterSectionRoom addContainerClasses="pt-3 border-top room" />
      <StoCscFilterSectionEnvironmentHealth
        addContainerClasses="pt-3 border-top environment-health"
      />
      <StoCscFilterSectionResilience
        addContainerClasses="pt-3 border-top resilience"
      />
      <StoCscFilterSectionDesignSurface
        addContainerClasses="pt-3 border-top design-surface"
      />
      <StoCscFilterSectionProcessing
        addContainerClasses="pt-3 border-top processing"
      />
      <StoCscFilterSectionProcessingTime
        addContainerClasses="pt-3 border-top processing-time"
      />
      <StoCscFilterSectionPriceLevel
        addContainerClasses="pt-3 border-top price-level"
      />
    </div>

    <StoCscFilterHeaderParameter />
    <StoCscProjectStats />
  </div>
</template>

<script>
import StoCscFilterHeader from "./Header.vue";
import StoCscFilterSectionSystemGroup from "./Section/SystemGroup.vue";
import StoCscFilterSectionRoom from "./Section/Room.vue";
import StoCscFilterSectionEnvironmentHealth from "./Section/EnvironmentHealth.vue";
import StoCscFilterSectionResilience from "./Section/Resilience.vue";
import StoCscFilterSectionDesignSurface from "./Section/DesignSurface.vue";
import StoCscFilterSectionProcessing from "./Section/Processing.vue";
import StoCscFilterSectionProcessingTime from "./Section/ProcessingTime.vue";
import StoCscFilterSectionPriceLevel from "./Section/PriceLevel.vue";
import StoCscFilterHeaderParameter from "./HeaderParameter.vue";
import StoCscProjectStats from "./ProjectStats.vue";

export default {
  name: "StoCscFilterGrid",
  components: {
    StoCscFilterHeader,
    StoCscFilterSectionSystemGroup,
    StoCscFilterSectionRoom,
    StoCscFilterSectionEnvironmentHealth,
    StoCscFilterSectionResilience,
    StoCscFilterSectionDesignSurface,
    StoCscFilterSectionProcessing,
    StoCscFilterSectionProcessingTime,
    StoCscFilterSectionPriceLevel,
    StoCscFilterHeaderParameter,
    StoCscProjectStats,
  },
  mounted() {
    const scrollIntoView = this.$cookies.get("sto-dk-intoview");
    const el = document.querySelector(".filter-header");
    if (el && scrollIntoView) {
      el.scrollIntoView();
    }
    if (this.$cookies.get("cwcc_functional"))
      this.$cookies.set(
        "sto-dk-intoview",
        true,
        "Fri, 31 Dec 9999 23:59:59 GMT"
      );
  },
};
</script>
