<template>
  <header class="row font-weight-bold bg-gray">
    <div class="col-12 col-lg-3 col-md-6 py-1 pr-0">
      {{ title + " " + shorthand }}
    </div>
    <div class="col-12 col-lg-9 col-md-6 d-none py-1 d-lg-block">
      <div class="row">
        <div class="col-4 pr-0"></div>
        <div class="col-8 pr-0">
          <div class="row mr-0">
            <div class="col-4 pr-0 text-left" v-html="orderList" />
            <div class="col-3 pr-0 text-left" v-html="labels" />
            <div class="col-3 pr-0 text-right" v-html="consumption" />
            <div class="col-2 pr-0 text-left" v-html="unit" />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { getString } from "../../../language";

export default {
  name: "StoCscResultItemHeaderMiddle",
  props: {
    shorthand: String,
  },
  data: function () {
    return {
      title: getString("result.construction.title"),
      orderList: getString("result.orderList.title"),
      labels: getString("result.orderList.labels"),
      consumption: getString("result.orderList.consumption"),
      unit: getString("result.orderList.unit"),
    };
  },
};
</script>
